window.show_onboarding_choose_location = function () {
    hide_allViews()
    $("#choose_location").removeClass("d-none")
    appData.current_user_view = "choose_location"
    showButtonBar(true, false)
    tempData.locationCord = undefined
    hide_bottom_primary_button()

    $("#add_location_input_field").val("")
    scroll.animateScroll(0);
    
    $("#bottom_action_bar_button_secondary").html("Back")
    $('.location_query_results').html($($('#location_options_template_empty').html()))
    init_location()
}


var hasUserSelectedOption = false

window.init_location = function () {

    mapkit.init({
        authorizationCallback: function (done) {
            done('eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ijk3Q0cyVlRVODUifQ.eyJpc3MiOiJSRzQ5UkJEQjJGIiwiaWF0IjoxNjgyNjE2NTE3LCJleHAiOjE3MTQwODk2MDB9.OfZMKULAnbQvZk2m4-hr5sbzmTf1mDdSwH3L4lrgKeu9HU_rrqGJYgN8akpJGusP41dN74QmTpddIWsz9yjHrw');
        }
    });

    const addressInput = document.getElementById('add_location_input_field');
    let timeoutId;

    addressInput.addEventListener('input', async (event) => {
        const query = event.target.value;

        if (!query) {
            hide_bottom_primary_button()
        } else {
            showButtonBar(true, false)
        }

        if (!query) {
            $('.location_query_results').html($($('#location_options_template_empty').html()))
            return;
        }

        if(hasUserSelectedOption){
            return
        }

        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            let search = new mapkit.Search();
            search.autocomplete(query, (error, data) => {
                if (error) {
                    return;
                }

                $(".location_query_results").html("")

                data.results.slice(0, 6).forEach(place => {
                    if (place.coordinate != undefined) {
                        var template = $($('#location_options_template').html());
                        $(template).find(".main_location_title").html(place.displayLines[0])
                        $(template).find(".secondary_location_title").html(place.displayLines[1])
                        $('.location_query_results').append(template);

                        template.on('click', function (e) {
                            e.preventDefault();

                            if($(this).hasClass("blue-color-border")) {

                                // unclick the field, remove the cords
                                hasUserSelectedOption = false
                                tempData.locationCord = undefined
                                $(this).removeClass("blue-color-border")

                            }else{

                                hasUserSelectedOption = true
                                $(".location_result_option").each(function () {
                                    $(this).removeClass("blue-color-border")
                                })
    
                                $(this).addClass("blue-color-border")
    
                                tempData.locationCord = [place.coordinate.latitude, place.coordinate.longitude]
                                var place_line_one = $(this).find(".main_location_title").html()
                                var place_line_two = $(this).find(".secondary_location_title").html()
                                $("#add_location_input_field").val(place_line_one + " " + place_line_two)

                            }
                        });
                    }
                });

                if (data.results.length == 0) {
                    $('.location_query_results').html($($('#location_options_template_empty').html()))
                }

            });
        }, 500);
    });


}