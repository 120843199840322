window.show_onboarding_choose_photos = function () {
    appData.current_user_view = "choose_photos"
    draw_choose_photos_views()
}

window.draw_choose_photos_views = function () {
    hide_allViews()
    $("#choose_photos").removeClass("d-none")
    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").addClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")
    drawSelectPhotosView()
}

$('#button_click_onboarding_choose_photos').on('click', function (e) {
    e.preventDefault();
    $("#file_upload_onboarding_photos").trigger("click");
})

window.drawSelectPhotosView = function () {
    var index = 0
    $('#photos_items_collection').html("")
    appData.photoList.forEach(function (photoUrl) {
        var template = $($('#photos_item_template').html());
        $(template).find(".photo_item").attr("photo-index", index)
        $(template).find(".photo_item").css('background-image', "url(" + photoUrl[0] + ")");
        $('#photos_items_collection').append(template);
        index = index + 1
    })

    $(".photo_item").each(function () {
        $(this).on('click', function (e) {
            e.preventDefault();
            $("#onboarding_remove_photo_from_picker_modal").modal("show");
            appData.selectedPhotoIndex = $(this).attr("photo-index");
        })
    });

    if (index > 0) {
        $("#bottom_action_bar_button_primary").removeClass("d-none")
        $("#bottom_action_bar_button_primary").html("Next")
    } else {
        $("#bottom_action_bar_button_primary").addClass("d-none")
    }

    scroll.animateScroll(0);
}

$('#onboarding_remove_photo_preview_action').on('click', function (e) {
    e.preventDefault();
    appData.photoList.splice(appData.selectedPhotoIndex, 1)
    drawSelectPhotosView()
    $("#onboarding_remove_photo_from_picker_modal").modal("hide");
})

$("#file_upload_onboarding_photos").on('change', function () {
    var imageFiles = $("#file_upload_onboarding_photos").prop("files")
    var maxFileSize = 15 * 1024 * 1024;

    for (let i = 0; i < imageFiles.length; i++) {
        const imageFile = imageFiles[i];
        var photoUrl = URL.createObjectURL(imageFile);
        if (imageFile.size > maxFileSize) {
            alert("This file is too big. Select a smaller photo file.")
            break;
        }
        if (appData.photoList.length > 20) {
            alert("Maximum amount of allowed photos. Keep it under 20 photos.")
            break;
        }
        appData.photoList.push([photoUrl, imageFile.name, imageFile])
    }

    drawSelectPhotosView()
});