window.show_onboarding_type_and_details = function (type) {
    hide_allViews()
    $("#create_greeting_screen").removeClass("d-none")

    scroll.animateScroll(0);

    appData.current_user_view = "onboarding_personalize"
    showButtonBar(false, false)

    if (type == "greeting") {

        $(".create_screen_title_text").text("Choose the occasion")
        $(".create_screen_subtitle_text").text("Let’s make a meaningful greeting that they’ll love.")
        $("#greeting_personalize_options_container").removeClass("d-none")
        $("#invite_personalize_options_container").addClass("d-none")
        appData.is_type_invite = false
        $("#bottom_action_bar_button_primary").html("Create your greeting")

    } else {

        appData.is_type_invite = true
        $(".create_screen_title_text").text("Make an event invite")
        $(".create_screen_subtitle_text").text("Create an event invite in less than 10 seconds. Get your party organized.")
        $("#greeting_personalize_options_container").addClass("d-none")
        $("#invite_personalize_options_container").removeClass("d-none")

        // Get the date and time fields
        const dateField = document.getElementById('create_event_date_field');
        const timeField = document.getElementById('create_event_time_field');

        // Get the current date and time
        $("#bottom_action_bar_button_primary").html("Create your invite")

        const now = new Date();

        // Set the value of the date field to the current date
        dateField.value = now.toISOString().slice(0, 10);

        // Build the options selector
        var hours, minutes, ampm;
        for (var i = 0; i <= 1440; i += 15) {  // extend to 1440 to include 24:00
            hours = Math.floor(i / 60) % 24;   // modulo 24 to convert 24 to 0
            minutes = i % 60;
            if (minutes < 10) {
                minutes = '0' + minutes; // adding leading zero
            }
            ampm = hours < 12 ? 'AM' : 'PM';

            var optionValue = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
            var displayHours = hours % 12;
            if (displayHours === 0) {
                displayHours = 12;
            }
            var optionText = displayHours + ':' + minutes + ' ' + ampm;
            $('#create_event_time_field').append($('<option>', {
                value: optionValue,
                text: optionText
            }));
        }

        // Set the value of the time field to the current time (without seconds)
        var currentHour = now.getHours();
        var currentMinutes = now.getMinutes();
        var nearest15Minutes = Math.ceil(currentMinutes / 15) * 15;
        if (nearest15Minutes === 60) {
            currentHour += 1;
            nearest15Minutes = 0;
        }
        // If the currentHour rounds up to 24, set it to 0
        if (currentHour === 24) {
            currentHour = 0;
        }
        timeField.value = `${currentHour.toString().padStart(2, '0')}:${nearest15Minutes.toString().padStart(2, '0')}`;


    }
}


/**
 * Function to check if the personalization form is complete and to perform
 * subsequent operations based on the user and form status.
 */
window.check_personalize_form_complete = function () {
    // Show the loading indicator
    show_loading_indicator_on_primary_bottom_bar(true);

    // If the user is logged in
    if (appData.user_logged_in == true) {
        let data = {}; // Object to store the data for Firebase function calls

        // If the operation is of type invite
        if (appData.is_type_invite) {
            // If the event name field is empty
            if ($("#create_event_name_field").val() == "") {
                $("#create_event_name_field").addClass("is-invalid");
                showError("Create your invite");
                return;
            }
            // Populate data object for invite
            data.event_name = $("#create_event_name_field").val();
            var event_has_time = $('#create_event_name_time_tbd_switch').is(':checked');

            if (!event_has_time) {
                const dateInput = $("#create_event_date_field").val();
                const timeInput = $("#create_event_time_field").val();
                data.event_date_time = new Date(dateInput + 'T' + timeInput); // Convert to seconds since the Unix epoch
            }

            data.event_has_time = !event_has_time;
            data.invite_date_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            data.referral_code = tempData.ref_code;
        }
        else {
            // If the recipient's name is not provided
            if ($("#recipients_name_personalize_card").val() == "") {
                $("#recipients_name_personalize_card").addClass("is-invalid");
                showError();
                return;
            }
            // Populate data object for greeting
            $("#recipients_name_personalize_card").removeClass("is-invalid");
            appData.occasion = $("#occasion_choose_prompt_dropdown").val();
            appData.recipient_name = $("#recipients_name_personalize_card").val();
            data.tag_id = appData.extracted_url_id;
            data.recipient_name = appData.recipient_name;
            data.user_selected_occasion = appData.occasion;
            data.referral_code = tempData.ref_code;
        }

        // Call appropriate Firebase function
        let firebaseFunction = appData.is_type_invite ? 'post_generateBlankInvite' : 'post_generateBlankGreeting';
        callFirebaseFunction(firebaseFunction, data)
            .then((id) => {
                // Call the generateNewPreviewThumbnail function
                return generateNewPreviewThumbnail(id);
            })
            .catch((error) => {
                // Log error and hide loading indicator
                console.error("Error: " + error);
                showError(error.message);
            });

    }
    else {
        show_onboarding_login("generate_new_greetie_code");
    }
}

// Function to show error and hide loading indicator
function showError(errorMessage = "") {
    // show_loading_indicator_on_primary_bottom_bar(false, errorMessage);
    $("#login_with_phone_number_verify_modal").modal("hide");
    $("#login_modal_verify_otc_button").html("Verify SMS Code");
    if (errorMessage) {
        alert(errorMessage);
    }
}

function callFirebaseFunction(firebaseFunctionName, data) {
    var firebaseFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, firebaseFunctionName);
    return firebaseFunction(data)
        .then((result) => {
            if (result == "create-limit-reached") {
                throw new Error('You have reached your limit on the number of greetings or invites you can create in 24 hours.');
            } else {
                appData.extracted_url_id = result.data.id;
                return appData.extracted_url_id;
            }
        });
}

// Function to generate a new preview thumbnail
function generateNewPreviewThumbnail(docId) {
    var post_generateNewPreviewThumbnail_function = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_generateNewPreviewThumbnail');
    return post_generateNewPreviewThumbnail_function({ docId: docId })
        .then((result) => {
            // Navigate to new URL on success
            let baseURL = window.location.origin;
            let newURL = baseURL + '/i/' + docId;
            window.location.href = newURL;
        });
}
