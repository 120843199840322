// window.show_onboarding_choose_expression = function () {

//     hide_allViews()
//     $('#prompt_select_container').html("")
//     $("#choose_expression_view").removeClass("d-none")

//     showButtonBar(true, false)
//     $("#bottom_action_bar_button_primary").addClass("d-none")
//     $("#bottom_action_bar_button_secondary").html("Back")

//     appData.current_user_view = "choose_expression_view"

//     scroll.animateScroll(0);
// }

// $('#button_click_onboarding_create_slideshow').on('click', function (e) {
//     e.preventDefault();
//     show_onboarding_choose_photos()
// })

// $('#button_click_onboarding_add_video').on('click', function (e) {
//     e.preventDefault();
//     show_onboarding_prompt_instructions()
// })

// $('#button_click_onboarding_add_a_note').on('click', function (e) {
//     e.preventDefault();
//     onboarding_show_write_note()
// })

// $('#button_click_onboarding_add_gif').on('click', function (e) {
//     e.preventDefault();
//     show_onboarding_choose_gif()
// })

// $('#button_click_onboarding_add_ai_writing').on('click', function (e) {
//     e.preventDefault();
//     show_onboarding_ai_writing()
// })