window.init_show_your_stuff = function () {
    appData.stuff_type_selection = "favs"
    $(".stuff_type_selector").each(function () {
        if ($(this).attr("data-type") == appData.stuff_type_selection) {
            $(this).addClass("active")
        } else {
            $(this).removeClass("active")
        }
        $(this).on('click', function (e) {
            if (appData.stuff_type_selection != $(this).attr("data-type")) {
                appData.stuff_type_selection = $(this).attr("data-type")
                show_stuff(appData.stuff_type_selection)
            }
        })
    })
    show_stuff("favs")
}

window.show_stuff = function (type) {

    $('#user_stuff_collection').html("")
    var loading_template = $($('#stuff_item_loading_template').html());
    $('#user_stuff_collection').append(loading_template);

    var get_userStuff = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_userStuff');
    get_userStuff({
        type: type
    }).then(function (results) {

        $('#user_stuff_collection').html("")

        var i = 0
        results.data.forEach(function (item) {

            var template;

            if (appData.stuff_type_selection != "profile") {
                template = $($('#stuff_item_template').html());
                $(template).find(".stuff_item").attr("stuff-id", i)
            } else {
                template = $($('#stuff_profile_template').html());
                $(template).find(".stuff_item").attr("stuff-id", i)
            }

            if (appData.stuff_type_selection == "favs" || appData.stuff_type_selection == "created") {
                var dateString = new Date(item["modify_date"]._seconds * 1000).toLocaleDateString("en-US")
                $(template).find(".stuff_item").attr("href", "/item/?id=" + item["id"])
                $(template).find(".stuff_title").html("Greeting from " + item["greeting_author"])
                $(template).find(".stuff_subtitle").html(dateString)
            }

            if (appData.stuff_type_selection == "birthdays") {
                var monthName = moment().month(item["birthday_month"] - 1).format("MMMM")
                $(template).find(".stuff_title").html(item["name"] + "'s Birthday")
                $(template).find(".stuff_subtitle").html("on " + monthName + " " + item["birthday_day"])
            }

            if (appData.stuff_type_selection == "profile") {
                var monthName = moment().month(item["birthday_month"] - 1).format("MMMM")
                $(template).find(".user-profile-name-string").html(item["name"])
                $(template).find(".user-account-birthday").html(monthName + " " + item["birthday_day"])
            }

            $('#user_stuff_collection').append(template);
            i = i + 1
        })

        if (appData.stuff_type_selection == "profile") {
            $(".reset_user_account").on('click', function (e) {
                $("#delete_account_modal").modal("show")
            })
        }

        $(".vibe_item").each(function () {
            $(this).on('click', function (e) {
                e.preventDefault();
                if (appData.stuff_type_selection == "favs" || appData.stuff_type_selection == "created") {
                    console.log("open link");
                }
                if (appData.stuff_type_selection == "birthdays") {
                    console.log("remove birthday option");
                }
            })
        });

        if (i == 0) {
            var template = $($('#stuff_item_empty_state_template').html());
            $('#user_stuff_collection').append(template);
        }

    }).catch((error) => {
        console.error('Error:', error);
        if(error.message == "You need to be logged in to see your stuff."){
            // Do something with the login dialog here
            show_onboarding_login("show_my_stuff")
            $('#user_stuff_collection').html("")
            var template = $($('#stuff_item_no_auth_state_template').html());
            $('#user_stuff_collection').append(template);
        }else{
            alert(error.message)
        }
    });

}

$("#confirm_delete_my_account").on('click', function (e) {
    $("#confirm_delete_my_account").html(
        `<div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>`
    );
    var post_deleteSelfAccount = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_deleteSelfAccount');
    post_deleteSelfAccount().then(function (results) {
        return firebaseUtils.signOut(firebaseUtils.auth)
    }).then(() => {
        location.reload();
        return false;
    }).catch((error) => {
        alert(error.message)
        $("#confirm_delete_my_account").html("Delete Everything Forever")
    });
})