// This function manages the wallpaper selection view
window.show_choose_vibe_background_view = function () {
    // Hide all views before displaying the vibe background view
    hide_allViews()
    $("#choose_vibe").removeClass("d-none")

    // Configuring the button bar 
    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").addClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")

    // Updating the current user view 
    appData.current_user_view = "choose_vibe"

    // Reset the scroll to top
    scroll.animateScroll(0);

    // Set default vibe selection to "random"
    tempData.vibe_selection = "random"

    // Call the function to show the vibe selection 
    window.show_onboarding_choose_vibe()
}

// This function fetches the wallpapers based on the vibe selection and manages the click events
window.show_onboarding_choose_vibe = function () {
    // To prevent multiple quick clicks while data is loading
    if (tempData.isLoading) {
        return;
    }

    tempData.isLoading = true;

    // Configure vibe_items_collection for loading state
    $('#vibe_items_collection').removeClass("row").addClass("text-center")
    $('#vibe_items_collection').html(
        `<div class="spinner-border text-muted align-self-center mt-10" role="status">
        <span class="visually-hidden">Loading...</span>
        </div>
        <p class="align-self-center text-center text-muted mt-3">Loading your stuff...</p>
    `);

    // Fetch all wallpapers using Firebase function
    var get_allWallpapersFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_wallpapersByTag');
    get_allWallpapersFunction({
        tag: tempData.vibe_selection
    }).then(function (result) {

        // Resetting vibe_items_collection after data loading
        $('#vibe_items_collection').addClass("row").removeClass("text-center")
        $('#vibe_items_collection').html("")

        // Looping through wallpapers data to create elements for each
        result.data.wallpapers.forEach(function (item, i) {
            var template = $($('#vibe_item_template').html());
            $(template).find(".vibe_item").attr("vibe-id", item["id"])
            $(template).find(".vibe_item").css('background-image', "url(" + item["thumbnailUrl"] + ")");
            $('#vibe_items_collection').append(template);
        })

        // Assigning click event to vibe_item elements
        $(".vibe_item").off('click').on('click', function (e) {
            e.preventDefault();
            $(".vibe_item").removeClass("color-border")
            $(this).addClass("color-border")
            appData.vibe_background_selection = $(this).attr("vibe-id")
            appData.vibe_background_selection_thumbnail = $(this).css('background-image').replace('url("', '').replace('")', '')
            $("#bottom_action_bar_button_primary").removeClass("d-none")
            $("#bottom_action_bar_button_primary").html("Next")
        });

        // Configuring and assigning click event to wallpaper_vibe_selector elements
        $(".wallpaper_vibe_selector").each(function () {
            if ($(this).attr("data-type") == tempData.vibe_selection) {
                $(this).addClass("active")
            } else {
                $(this).removeClass("active")
            }
            $(this).off('click').on('click', function (e) {
                tempData.vibe_selection = $(this).attr("data-type")
                show_onboarding_choose_vibe()
            })
        })

        // Reset loading state
        tempData.isLoading = false;

    }).catch((error) => {
        console.log(error);
        alert(error.message)

        // Reset loading state in case of error
        tempData.isLoading = false;
    });
}
