
var tempMusicArray;

window.show_onboarding_choose_music = function () {

    hide_allViews()
    $("#choose_music").removeClass("d-none")

    var previous_user_view = appData.current_user_view
    appData.current_user_view = "choose_music"

    // If we are in an edit flow, then append edit to the name of the view
    if (previous_user_view == "choose_photos_edit") {
        appData.current_user_view = appData.current_user_view + "_edit"
    }

    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").addClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")
    $(".bottom_action_bar_small_skip_music_link").removeClass("d-none")

    tempMusicArray = shuffle(appData.musicSelection)

    drawSelectMusicView()
}

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
}

window.drawSelectMusicView = function () {

    $('#music_items_collection').html("")

    tempMusicArray.forEach(function (item) {
        var template = $($('#music_item_template').html());
        $(template).find(".music_item").attr("music-id", item[2])
        $(template).find(".music_title").html(item[0])
        $(template).find(".music_author").html(item[1])
        if (item[2] == appData.selectedMusicId) {
            $(template).find(".special-btn").removeClass("special-secondary-btn")
            $(template).find(".bi-play-circle-fill").removeClass("bi-play-circle-fill").addClass("bi-pause-circle-fill")
        }
        $('#music_items_collection').append(template);
    })

    $(".music_item").each(function () {
        $(this).on('click', function (e) {
            e.preventDefault();
            if (appData.selectedMusicFile != "") {
                appData.selectedMusicFile.pause();
            }
            if (appData.selectedMusicId != $(this).attr("music-id")) {
                appData.selectedMusicId = $(this).attr("music-id")
                appData.selectedMusicFile = new Audio(returnMusicFileForId(appData.selectedMusicId));
                appData.selectedMusicFile.play();
                $("#bottom_action_bar_button_primary").removeClass("d-none")
                $("#bottom_action_bar_button_primary").html("Save")
            } else {
                $("#bottom_action_bar_button_primary").addClass("d-none")
                appData.selectedMusicId = "-1"
            }
            drawSelectMusicView()
        })
    });
}

function returnMusicFileForId(id){
    var temp;
    appData.musicSelection.forEach(function (item) {
        if(id == item[2]){
            temp = item[3]
        }
    })
    return temp
}

window.skipAddingMusic = function () {

    if (appData.selectedMusicFile != "") {
        appData.selectedMusicFile.pause();
    }

    appData.selectedMusicFile = undefined
    appData.selectedMusicId = -1
    if (appData.user_logged_in) {
        $(".bottom_action_bar_small_skip_music_link").addClass("d-none");
        show_onboarding_uploading_shopping()
        return
    } else {
        show_onboarding_login("choose_music_skip")
        return
    }
}