window.show_onboarding_ai_writing_review = function () {

    hide_allViews()
    $("#choose_ai_writing_review").removeClass("d-none")

    appData.current_user_view = "choose_ai_writing_review"

    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").removeClass("d-none")    
    $("#bottom_action_bar_button_primary").html("Save this poem")
    $("#bottom_action_bar_button_secondary").html("Back")

    scroll.animateScroll(0);

}