window.init_suggest_expressions = function () {

    appData.suggestions_array = {
        "anniversary": [
            {
                "title": "A video describing how you and them met",
                "icon": "video_expression"
            },
            {
                "title": "Make a slideshow of photos of you and them",
                "icon": "photo_expression"
            },
            {
                "title": "Add a custom AI generated love poem for them.",
                "icon": "ai_poem_expression"
            },
            {
                "title": "Write a heartfelt note reminiscing about your relationship",
                "icon": "note_expression"
            },
            {
                "title": "Find a meaningful gift that symbolizes your love",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny 'Happy Anniversary' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Record a video answering questions about your relationship",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow with your favorite couple's songs",
                "icon": "photo_expression"
            }
        ],
        "invite": [
            {
                "title": "Add a location to your party",
                "icon": "location_expression"
            },
            {
                "title": "Let AI write the party details",
                "icon": "ai_poem_expression"
            },
            {
                "title": "Record a fun video party invitation or add a gif",
                "icon": "gif_expression"
            }
        ],
        "apology": [
            {
                "title": "Record a sincere apology video",
                "icon": "video_expression"
            },
            {
                "title": "Write a heartfelt apology note",
                "icon": "note_expression"
            },
            {
                "title": "Add a funny 'I'm sorry' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Create a custom AI-generated apology poem",
                "icon": "ai_poem_expression"
            },
            {
                "title": "Upload a photo that captures a memorable moment together",
                "icon": "photo_expression"
            }
        ],
        "birthday": [
            {
                "title": "Record a birthday wish video",
                "icon": "video_expression"
            },
            {
                "title": "Create a birthday photo slideshow",
                "icon": "photo_expression"
            },
            {
                "title": "Write a personalized birthday note",
                "icon": "note_expression"
            },
            {
                "title": "Find the perfect birthday gift",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny birthday GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI birthday poem",
                "icon": "ai_poem_expression"
            }
        ],
        "christmas": [
            {
                "title": "Record a Christmas greeting video",
                "icon": "video_expression"
            },
            {
                "title": "Create a Christmas-themed photo slideshow",
                "icon": "photo_expression"
            },
            {
                "title": "Write a heartwarming Christmas note",
                "icon": "note_expression"
            },
            {
                "title": "Find the perfect Christmas gift",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny Christmas GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI Christmas poem",
                "icon": "ai_poem_expression"
            }
        ],
        "congratulations": [
            {
                "title": "Record a congratulatory video message",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow celebrating their achievement",
                "icon": "photo_expression"
            },
            {
                "title": "Write a personalized note of congratulations",
                "icon": "note_expression"
            },
            {
                "title": "Find a meaningful gift to mark the occasion",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny 'Congrats' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI congratulatory poem",
                "icon": "ai_poem_expression"
            }
        ],
        "encouragement": [
            {
                "title": "Record an uplifting and supportive video message",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow of shared memories",
                "icon": "photo_expression"
            },
            {
                "title": "Write a heartfelt note of encouragement",
                "icon": "note_expression"
            },
            {
                "title": "Find a gift that shows you believe in them",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny and motivational GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI poem to inspire them",
                "icon": "ai_poem_expression"
            }
        ],
        "friendship": [
            {
                "title": "Record a video message celebrating your friendship",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow of your favorite moments together",
                "icon": "photo_expression"
            },
            {
                "title": "Write a note expressing your appreciation for their friendship",
                "icon": "note_expression"
            },
            {
                "title": "Find a gift that symbolizes your bond",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny 'Best Friends' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI poem about your friendship",
                "icon": "ai_poem_expression"
            }
        ],
        "get_well_soon": [
            {
                "title": "Record a video message wishing them a speedy recovery",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow to lift their spirits",
                "icon": "photo_expression"
            },
            {
                "title": "Write a heartfelt 'Get Well Soon' note",
                "icon": "note_expression"
            },
            {
                "title": "Find a thoughtful gift to help them feel better",
                "icon": "gift_expression"
            },
            {
                "title": "Add a 'Get Well Soon' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI 'Get Well Soon' poem",
                "icon": "ai_poem_expression"
            }
        ],
        "graduation": [
            {
                "title": "Record a video message congratulating them on their graduation",
                "icon": "video_expression"
            },
            {
                "title": "Generate a custom AI graduation poem",
                "icon": "ai_poem_expression"
            },
            {
                "title": "Create a photo slideshow of their academic journey",
                "icon": "photo_expression"
            },
            {
                "title": "Write a personalized note celebrating their accomplishments",
                "icon": "note_expression"
            },
            {
                "title": "Find a meaningful graduation gift",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny 'Graduation' GIF",
                "icon": "gif_expression"
            }

        ],
        "hanukkah": [
            {
                "title": "Record a Hanukkah video greeting",
                "icon": "video_expression"
            },
            {
                "title": "Generate a custom AI Hanukkah poem",
                "icon": "ai_poem_expression"
            },
            {
                "title": "Create a photo slideshow of past Hanukkah celebrations",
                "icon": "photo_expression"
            },
            {
                "title": "Write a heartwarming Hanukkah note",
                "icon": "note_expression"
            },
            {
                "title": "Find the perfect Hanukkah gift",
                "icon": "gift_expression"
            },
            {
                "title": "Add a Hanukkah GIF",
                "icon": "gif_expression"
            }

        ],
        "just_because": [
            {
                "title": "Record a spontaneous video message",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow of random memories",
                "icon": "photo_expression"
            },
            {
                "title": "Generate a custom AI 'Just Because' poem",
                "icon": "ai_poem_expression"
            },
            {
                "title": "Write a thoughtful 'Just Because' note",
                "icon": "note_expression"
            },
            {
                "title": "Find a gift that shows you care",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny 'Just Because' GIF",
                "icon": "gif_expression"
            }

        ],
        "new_baby": [
            {
                "title": "Record a video message welcoming the new baby",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow of the baby's first moments",
                "icon": "photo_expression"
            },
            {
                "title": "Write a heartfelt note to the new parents",
                "icon": "note_expression"
            },
            {
                "title": "Find a thoughtful gift for the new baby",
                "icon": "gift_expression"
            },
            {
                "title": "Add a 'New Baby' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI poem for the new baby",
                "icon": "ai_poem_expression"
            }
        ],
        "sympathy": [
            {
                "title": "Record a heartfelt sympathy video message",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow of cherished memories",
                "icon": "photo_expression"
            },
            {
                "title": "Write a compassionate sympathy note",
                "icon": "note_expression"
            },
            {
                "title": "Find a thoughtful gift to show your support",
                "icon": "gift_expression"
            },
            {
                "title": "Add a comforting GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI sympathy poem",
                "icon": "ai_poem_expression"
            }
        ],

        
        "thanks_for_invite": [
            {
                "title": "Record a sincere thank you video message",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow showing your gratitude",
                "icon": "photo_expression"
            },
            {
                "title": "Write a heartfelt thank you note",
                "icon": "note_expression"
            },
            {
                "title": "Find a gift that expresses your appreciation",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny 'Thank You' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI thank you poem",
                "icon": "ai_poem_expression"
            }
        ],

        "thank_you": [
            {
                "title": "Record a sincere thank you video message",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow showing your gratitude",
                "icon": "photo_expression"
            },
            {
                "title": "Write a heartfelt thank you note",
                "icon": "note_expression"
            },
            {
                "title": "Find a gift that expresses your appreciation",
                "icon": "gift_expression"
            },
            {
                "title": "Add a funny 'Thank You' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI thank you poem",
                "icon": "ai_poem_expression"
            }
        ],
        "thinking_of_you": [
            {
                "title": "Record a video message to let them know you're thinking of them",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow of memorable moments",
                "icon": "photo_expression"
            },
            {
                "title": "Write a thoughtful 'Thinking of You' note",
                "icon": "note_expression"
            },
            {
                "title": "Find a gift to show you care",
                "icon": "gift_expression"
            },
            {
                "title": "Add a 'Thinking of You' GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI 'Thinking of You' poem",
                "icon": "ai_poem_expression"
            }
        ],
        "wedding": [
            {
                "title": "Record a heartfelt wedding wishes video",
                "icon": "video_expression"
            },
            {
                "title": "Create a photo slideshow of the couple's journey",
                "icon": "photo_expression"
            },
            {
                "title": "Write a personalized wedding congratulations note",
                "icon": "note_expression"
            },
            {
                "title": "Find the perfect wedding gift",
                "icon": "gift_expression"
            },
            {
                "title": "Add a 'Wedding' related GIF",
                "icon": "gif_expression"
            },
            {
                "title": "Generate a custom AI wedding poem",
                "icon": "ai_poem_expression"
            }
        ]


    }

}