window.show_onboarding_ready_to_upload = function () {

    appData.current_user_view = "confirm_upload"
    draw_ready_to_upload()
}

window.draw_ready_to_upload = function () {

    hide_allViews()
    showButtonBar(true, false)
    $("#bottom_action_bar_button_secondary").html("Back")

    if (appData.user_logged_in == false) {
        $("#bottom_action_bar_button_primary").html("Confirm Your Phone Number & Save")
    } else {
        $("#bottom_action_bar_button_primary").html("Save video")
    }

    $("#confirm_upload_view").removeClass("d-none")

    scroll.animateScroll(0);
}