window.show_loading_indicator_on_primary_bottom_bar = function (isloading, string) {
    if (isloading == false) {
        $("#bottom_action_bar_button_primary").html(string)
    } else {
        $("#bottom_action_bar_button_primary").html(
            `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>`
        );
    }
}

window.show_loading_indicator_on_secondary_bottom_bar = function (isloading, string) {
    if (isloading == false) {
        $("#bottom_action_bar_button_secondary").html(string)
    } else {
        $("#bottom_action_bar_button_secondary").html(
            `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>`
        );
    }
}

window.hide_bottom_primary_button = function () {
    $("#bottom_action_bar_button_primary").addClass("d-none")
}

window.show_share_invite_bottom_bar = function () {
    resetBottomBarButtons()
    $("#button_action_bar").removeClass("d-none")
    $(".invite_to_event_bottom_bar_container").removeClass("d-none")
    $(".invite_to_event_bottom_bar_button").html("Share Link")
}

window.show_share_greeting_bottom_bar = function () {
    resetBottomBarButtons()
    $("#button_action_bar").removeClass("d-none")
    $(".invite_to_event_bottom_bar_container").removeClass("d-none")
    $(".invite_to_event_bottom_bar_button").html("Share Link")
}

window.show_back_greeting_bottom_bar = function () {
    resetBottomBarButtons()
    $("#button_action_bar").removeClass("d-none")
    $(".back_to_invite_bottom_bar_container").removeClass("d-none")
}

window.show_rsvp_invite_bottom_bar = function () {
    resetBottomBarButtons()
    $("#button_action_bar").removeClass("d-none")
    $('.rvsp_invite_bottom_bar_button').addClass('special-secondary-btn');
    $('.rvsp_invite_bottom_bar_button[data-response="' + appData.user_rsvp_status + '"]').removeClass("special-secondary-btn")
    $(".rvsp_invite_bottom_bar_container").removeClass("d-none")
}

window.show_rsvp_complete_invite_bottom_bar = function () {
    resetBottomBarButtons()
    $("#button_action_bar").removeClass("d-none")
    $(".rvsp_complete_invite_bottom_bar_container").removeClass("d-none")
}

/**
 * Resets the bottom bar buttons by hiding various elements.
 */
window.resetBottomBarButtons = function () {
    // Hide the button action bar
    $("#button_action_bar").addClass("d-none");

    // Hide the invite containers
    $(".rvsp_invite_bottom_bar_container").addClass("d-none");
    $(".invite_to_event_bottom_bar_container").addClass("d-none");
    $(".rvsp_complete_invite_bottom_bar_container").addClass("d-none");

    // Hide the back to invite container
    $(".back_to_invite_bottom_bar_container").addClass("d-none");

    // Hide the legal text action bar
    $("#legal_text_action_bar").addClass("d-none");

    // Hide primary and secondary action bar buttons
    $("#bottom_action_bar_button_primary").addClass("d-none");
    $("#bottom_action_bar_button_secondary").addClass("d-none");

    // Hide small secondary link and skip music link
    $("#bottom_action_bar_small_secondary_link").addClass("d-none");
    $(".bottom_action_bar_small_skip_music_link").addClass("d-none");

    // Hide the video upload file input
    $("#video_upload_file_input").addClass("d-none");
};


/**
 * Handles the click event on the primary action bar button.
 */
$("#bottom_action_bar_button_primary").on('click', function (e) {
    // Check the current user view and perform the corresponding action

    // Onboarding Personalize
    if (appData.current_user_view === "onboarding_personalize") {
        check_personalize_form_complete();
        return;
    }

    // Choose Photos
    if (
        appData.current_user_view === "choose_photos" ||
        appData.current_user_view === "choose_photos_edit"
    ) {
        show_onboarding_choose_music();
        return;
    }

    // Choose Location
    if (appData.current_user_view === "choose_location") {
        show_onboarding_uploading_shopping();
        return;
    }

    // Write Note, Write Note Review, Write Note Edit
    if (
        appData.current_user_view === "write_note" ||
        appData.current_user_view === "write_note_review" ||
        appData.current_user_view === "write_note_edit"
    ) {
        if (appData.user_logged_in) {
            show_onboarding_uploading_shopping();
        } else {
            show_onboarding_login("write_note");
        }
        return;
    }

    // Choose AI Writing Review
    if (appData.current_user_view === "choose_ai_writing_review") {
        show_onboarding_uploading_shopping();
        return;
    }

    // Choose GIF, Choose GIF Edit
    if (
        appData.current_user_view === "choose_gif" ||
        appData.current_user_view === "choose_gif_edit"
    ) {
        if (appData.user_logged_in) {
            show_onboarding_uploading_shopping();
        } else {
            show_onboarding_login("choose_gif");
        }
        return;
    }

    // Uploading and Shop
    if (appData.current_user_view === "uploading_and_shop") {
        show_loading_indicator_on_primary_bottom_bar(true, "Next")
        pull_recent_greetie_details();
        return;
    }

    // Choose Font
    if (appData.current_user_view === "choose_font") {
        update_tag_font();
        return;
    }

    // Choose Vibe
    if (appData.current_user_view === "choose_vibe") {
        show_onboarding_choose_font();
        return;
    }

    // Choose AI Writing
    if (appData.current_user_view === "choose_ai_writing") {
        if (appData.user_logged_in) {
            fetch_ai_writing_poem();
        } else {
            show_onboarding_login("choose_ai_writing");
        }
        return;
    }

    // Choose Music, Choose Music Edit
    if (
        appData.current_user_view === "choose_music" ||
        appData.current_user_view === "choose_music_edit"
    ) {
        appData.selectedMusicFile.pause();
        $(".bottom_action_bar_small_skip_music_link").addClass("d-none");
        if (appData.user_logged_in) {
            show_onboarding_uploading_shopping();
        } else {
            show_onboarding_login("choose_music");
        }
        return;
    }

    // Confirm Upload, Confirm Upload Edit
    if (
        appData.current_user_view === "confirm_upload" ||
        appData.current_user_view === "confirm_upload_edit"
    ) {
        if (appData.user_logged_in) {
            show_onboarding_uploading_shopping();
        } else {
            show_onboarding_login("confirm_upload");
        }
        return;
    }

    // Watch Greetie
    if (appData.current_user_view === "watch_greetie") {
        save_updated_expressions_array();
        return;
    }
});



$("#bottom_action_bar_button_secondary").on('click', function (e) {
    if (appData.current_user_view == "watch_greetie") {
        hide_reorder_delete_greeting_expressions();
        return
    }
    if (appData.current_user_view == "choose_expression_view") {
        show_onboarding_type_and_details();
        return
    }
    if (appData.current_user_view == "select_prompt") {
        show_onboarding_choose_expression();
        return
    }
    if (appData.current_user_view == "choose_photos") {
        show_onboarding_choose_expression();
        return
    }

    if (appData.current_user_view == "write_note_review") {
        resetNoteVeiwFromAIResult();
        return
    }

    if (appData.current_user_view == "choose_music") {
        $(".bottom_action_bar_small_skip_music_link").addClass("d-none")
        appData.selectedMusicFile.pause();
        show_onboarding_choose_photos();
        return
    }
    if (appData.current_user_view == "choose_music_edit") {
        appData.current_user_view = "choose_photos_edit"
        draw_choose_photos_views();

        if (appData.selectedMusicFile != "") {
            appData.selectedMusicFile.pause();
        }

        return
    }
    if (appData.current_user_view == "confirm_upload") {
        show_onboarding_prompt_instructions();
        // Need to clear out the upload or else you can't edit it
        $(".video_upload_file_input").val(undefined)
        return
    }
    if (appData.current_user_view == "confirm_upload_edit") {
        appData.current_user_view = "select_prompt_edit"
        draw_prompt_instructions();
        // Need to clear out the upload or else you can't edit it
        $(".video_upload_file_input").val(undefined)
        return
    }
    if (appData.current_user_view == "choose_photos_edit" || appData.current_user_view == "select_prompt_edit") {
        show_latest_greetie();
        show_edit_modal();
        return
    }
    if (appData.current_user_view == "write_note_edit") {
        show_latest_greetie();
        show_edit_modal();
        return
    }
    if (appData.current_user_view == "choose_location") {
        show_latest_greetie();
        show_edit_modal();
        return
    }

    if (appData.current_user_view == "choose_gif_edit") {
        show_latest_greetie();
        show_edit_modal();
        return
    }
    if (appData.current_user_view == "choose_ai_writing") {
        show_latest_greetie();
        show_edit_modal();
        return
    }

    if (appData.current_user_view == "choose_ai_writing_review") {
        show_onboarding_ai_writing();
        return
    }
    if (appData.current_user_view == "choose_gif") {
        show_onboarding_choose_expression();
        return
    }
    if (appData.current_user_view == "select_gift_edit") {
        $(".uploading_view_elem_within_uploading").removeClass("d-none")
        show_latest_greetie();
        show_edit_modal();
        return
    }
    if (appData.current_user_view == "choose_vibe") {
        show_latest_greetie();
        return
    }

    if (appData.current_user_view == "uploading_and_shop") {
        show_loading_indicator_on_secondary_bottom_bar(true, "Next")
        pull_recent_greetie_details();
        show_edit_modal();
        return
    }

    // This would only come from choose font so I don't want to make it reset the view.
    // This is why it uses the background view to just show and hide. We don't need to 
    // Remove it compaetly from the DOM
    if (appData.current_user_view == "choose_font") {
        show_choose_vibe_background_view()
        return
    }
})

window.showButtonBar = function (showSecondaryButton, showLegalText) {
    resetBottomBarButtons()
    $("#button_action_bar").removeClass("d-none")
    $("#bottom_action_bar_button_primary").removeClass("d-none")
    $("#bottom_action_bar_small_secondary_link").addClass("d-none")

    if (showLegalText == true) {
        $("#legal_text_action_bar").removeClass("d-none")
    } else {
        $("#legal_text_action_bar").addClass("d-none")
    }

    if (showSecondaryButton == true) {
        $("#bottom_action_bar_button_secondary").removeClass("d-none")
    } else {
        $("#bottom_action_bar_button_secondary").addClass("d-none")
    }
}

$(".bottom_action_bar_small_skip_music_link").on('click', function (e) {
    e.preventDefault();
    skipAddingMusic()
})