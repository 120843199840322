window.init_data_music = function () {

    appData.musicSelection = [
        [
            "Fantasy",
            "DRUBEATZ",
            "001",
            "/assets/img/music/001.mp3"
        ],
        [
            "Just Cool",
            "Wowa",
            "002",
            "/assets/img/music/002.mp3"
        ],
        [
            "Chill",
            "Wanheda",
            "003",
            "/assets/img/music/003.mp3"
        ],
        [
            "Night Rider",
            "jiglr",
            "004",
            "/assets/img/music/004.mp3"
        ],
        [
            "Runaway",
            "Beau Walker",
            "005",
            "/assets/img/music/005.mp3"
        ],
        [
            "Alter",
            "Hunter Milo",
            "006",
            "/assets/img/music/006.mp3"
        ],
        [
            "Voss",
            "JAK",
            "007",
            "/assets/img/music/007.mp3"
        ],
        [
            "Feels",
            "Jay Someday",
            "008",
            "/assets/img/music/008.mp3"
        ],
        [
            "Hold On",
            "Markvard",
            "009",
            "/assets/img/music/009.mp3"
        ],
        [
            "Let it Out",
            "Bryo",
            "010",
            "/assets/img/music/010.mp3"
        ],
        [
            "Mosaic",
            "tubebackr",
            "011",
            "/assets/img/music/011.mp3"
        ],
        [
            "Kalon",
            "extenz",
            "012",
            "/assets/img/music/012.mp3"
        ],
        [
            "Chill with Me",
            "Aftertune",
            "013",
            "/assets/img/music/013.mp3"
        ],
        [
            "Dakar",
            "LINE-NINE",
            "014",
            "/assets/img/music/014.mp3"
        ],
        [
            "Soda",
            "JAK",
            "015",
            "/assets/img/music/015.mp3"
        ],
        [
            "When Winter Rises",
            "Vendredi",
            "016",
            "/assets/img/music/016.mp3"
        ],
        [
            "Baybe",
            "Jay Someday",
            "017",
            "/assets/img/music/017.mp3"
        ],
        [
            "Snowy Time of the Year",
            "vgl9",
            "018",
            "/assets/img/music/018.mp3"
        ],
        [
            "Falling for You",
            "Markvard",
            "019",
            "/assets/img/music/019.mp3"
        ],
        [
            "Inside U",
            "Jay Someday",
            "020",
            "/assets/img/music/020.mp3"
        ],
        [
            "Mango",
            "tubebackr",
            "021",
            "/assets/img/music/021.mp3"
        ],
        [
            "Island Soul",
            "Skirk",
            "022",
            "/assets/img/music/022.mp3"
        ],
        [
            "Beach Lover",
            "Justhea",
            "023",
            "/assets/img/music/023.mp3"
        ],
        [
            "Odyssey",
            "jiglr",
            "024",
            "/assets/img/music/024.mp3"
        ]
    ]
}