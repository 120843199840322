// window.show_onboarding_type_and_details = function () {
//     hide_allViews()
//     $("#create_greeting_screen").removeClass("d-none")

//     scroll.animateScroll(0);

//     appData.current_user_view = "onboarding_personalize"
//     showButtonBar(false, false)

//     if (appData.extracted_url_id === undefined) {
//         create_change_create_string("new-id")
//     } else {
//         create_change_create_string("known-id")
//     }
// }

// function create_change_create_string(type) {
//     if (type === "known-id") {
//         $("#bottom_action_bar_button_primary").html("Get started")
//     }
//     if (type === "new-id") {
//         $("#bottom_action_bar_button_primary").html("Create your greeting")
//     }
//     if (type === "invite") {
//         $("#bottom_action_bar_button_primary").html("Create your invite")
//     }
// }




$("#choose_greeting_type_container").on('click', '.segmented_control_button_not_active', function (e) {
    e.preventDefault();
    // Remove the active class from all buttons and add the not_active class
    $(".segmented_control_button_active").removeClass("segmented_control_button_active").addClass("segmented_control_button_not_active");
    // Add the active class to the clicked button and remove the not_active class
    $(this).addClass("segmented_control_button_active").removeClass("segmented_control_button_not_active");

    // Find the index of the clicked button
    const buttonIndex = $("#choose_greeting_type_container .col").index(this);
    if (buttonIndex == 0) {
        $(".create_screen_title_text").text("Choose the occasion")
        $(".create_screen_subtitle_text").text("Let’s make a meaningful greeting that they’ll love.")
        $("#greeting_personalize_options_container").removeClass("d-none")
        $("#invite_personalize_options_container").addClass("d-none")
        appData.is_type_invite = false
        if (appData.extracted_url_id === undefined) {
            create_change_create_string("new-id")
        } else {
            create_change_create_string("known-id")
        }
    } else {
        appData.is_type_invite = true
        $(".create_screen_title_text").text("Make an event invite")
        $(".create_screen_subtitle_text").text("Create an event invite in less than 10 seconds. Get your party organized.")
        $("#greeting_personalize_options_container").addClass("d-none")
        $("#invite_personalize_options_container").removeClass("d-none")
        create_change_create_string("invite")

        // Get the date and time fields
        const dateField = document.getElementById('create_event_date_field');
        const timeField = document.getElementById('create_event_time_field');

        // Get the current date and time
        const now = new Date();

        // Set the value of the date field to the current date
        dateField.value = now.toISOString().slice(0, 10);

        // Build the options selector
        var hours, minutes, ampm;
        for (var i = 0; i <= 1425; i += 15) {
            hours = Math.floor(i / 60);
            minutes = i % 60;
            if (minutes < 10) {
                minutes = '0' + minutes; // adding leading zero
            }
            ampm = hours % 24 < 12 ? 'AM' : 'PM';

            // ie. value = 22:30, text = "10:30 PM"
            var optionValue = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
            hours = hours % 12;
            if (hours === 0) {
                hours = 12;
            }
            var optionText = hours + ':' + minutes + ' ' + ampm;
            $('#create_event_time_field').append($('<option>', {
                value: optionValue,
                text: optionText
            }));
        }
        // Set the value of the time field to the current time (without seconds)
        const currentHour = now.getHours().toString().padStart(2, '0');
        const currentMinutes = now.getMinutes();
        const nearest15Minutes = Math.ceil(currentMinutes / 15) * 15;
        timeField.value = `${currentHour}:${nearest15Minutes.toString().padStart(2, '0')}`;
    }
});

window.switchToInviteOption = function () {
    // Remove the active class from all buttons and add the not_active class
    $(".segmented_control_button_active").removeClass("segmented_control_button_active").addClass("segmented_control_button_not_active");

    // Assume that the invite button is the second button in the segmented control
    const inviteButton = $("#choose_greeting_type_container .col").eq(1);

    // Add the active class to the invite button and remove the not_active class
    inviteButton.addClass("segmented_control_button_active").removeClass("segmented_control_button_not_active");

    // Set is_type_invite to true in appData
    appData.is_type_invite = true

    // Show the invite personalize options and hide the greeting personalize options
    $("#greeting_personalize_options_container").addClass("d-none");
    $("#invite_personalize_options_container").removeClass("d-none");
    $(".create_screen_title_text").text("Make an event invite")
    $(".create_screen_subtitle_text").text("Create an event invite in less than 10 seconds. Get your party organized.")

    create_change_create_string("invite");

    // Get the date and time fields
    const dateField = document.getElementById('create_event_date_field');
    const timeField = document.getElementById('create_event_time_field');

    // Get the current date and time
    const now = new Date();

    // Set the value of the date field to the current date
    dateField.value = now.toISOString().slice(0, 10);

    // Build the options selector
    var hours, minutes, ampm;
    for (var i = 0; i <= 1425; i += 15) {
        hours = Math.floor(i / 60);
        minutes = i % 60;
        if (minutes < 10) {
            minutes = '0' + minutes; // adding leading zero
        }
        ampm = hours % 24 < 12 ? 'AM' : 'PM';

        // ie. value = 22:30, text = "10:30 PM"
        var optionValue = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
        hours = hours % 12;
        if (hours === 0) {
            hours = 12;
        }
        var optionText = hours + ':' + minutes + ' ' + ampm;
        $('#create_event_time_field').append($('<option>', {
            value: optionValue,
            text: optionText
        }));
    }
    // Set the value of the time field to the current time (without seconds)
    const currentHour = now.getHours().toString().padStart(2, '0');
    const currentMinutes = now.getMinutes();
    const nearest15Minutes = Math.ceil(currentMinutes / 15) * 15;
    timeField.value = `${currentHour}:${nearest15Minutes.toString().padStart(2, '0')}`;
}



$('#create_event_name_time_tbd_switch').change(function () {
    if ($(this).is(":checked")) {
        $(".create_invite_time_options_container").addClass("d-none")
    } else {
        $(".create_invite_time_options_container").removeClass("d-none")
    }
});


// window.check_personalize_form_complete = function () {

//     // Open the shop, load the new greeting then show it
//     show_loading_indicator_on_primary_bottom_bar(true)

//     if (appData.user_logged_in == true) {
//         // We should show a spinner for both?
//         // - This would create a new code 
//         // - OR add some variables to an existing code

//         // There is a code here

//         if (appData.is_type_invite) {

//             if ($("#create_event_name_field").val() == "") {
//                 $("#create_event_name_field").addClass("is-invalid");
//                 show_loading_indicator_on_primary_bottom_bar(false, "Create your invite")
//             } else {

//                 var event_has_time = $('#create_event_name_time_tbd_switch').is(':checked')
//                 var inputDateTime = undefined

//                 if (!event_has_time) {
//                     const dateInput = $("#create_event_date_field").val()
//                     const timeInput = $("#create_event_time_field").val()
//                     inputDateTime = new Date(dateInput + 'T' + timeInput);
//                 }

//                 var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//                 var post_generateBlankInviteFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_generateBlankInvite');
//                 post_generateBlankInviteFunction({
//                     event_name: $("#create_event_name_field").val(),
//                     event_has_time: !event_has_time,
//                     event_date_time: inputDateTime,
//                     invite_date_timezone: timezone
//                 }).then(function (result) {

//                     $("#login_with_phone_number_verify_modal").modal("hide");
//                     $("#login_modal_verify_otc_button").html("Verify SMS Code");

//                     if (result == "create-limit-reached") {
//                         throw new Error('You have reached your limit on the number of greetings or invites you can create in 24 hours.');
//                     } else {
//                         appData.extracted_url_id = result.data.id
//                         window.history.pushState({}, document.title, "/i/" + appData.extracted_url_id);

//                         appData.expression_array = result.data.expression_array
//                         appData.occasion = result.data.occasion
//                         appData.is_deletable_code = result.data.is_deletable_code

//                         appData.is_saved_greeting = result.data.user_saved_card
//                         appData.sender_name = result.data.sender_name
//                         appData.has_recipient_claimed = result.data.has_recipient_claimed
//                         appData.can_this_user_modify_greeting = result.data.canThisUserWriteToCurrentTag
//                         appData.cardType = result.data.cardType
//                         appData.background_image = result.data.background_image
//                         appData.font_type = result.data.font_type
//                         appData.font_theme = result.data.font_theme
//                         appData.has_date_time_set = result.data.has_date_time_set
//                         appData.rsvp_array = result.data.rsvp_array

//                         if (result.data.invite_date_time !== undefined && result.data.invite_date_time !== null) {
//                             appData.invite_date_time = new Date(parseInt(result.data.invite_date_time._seconds, 10) * 1000)
//                         }

//                         appData.invite_name = result.data.invite_name
//                         appData.is_type_invite = true

//                     }

//                 }).then(function (result) {

//                     drawGreetie()

//                     var post_generateNewPreviewThumbnail_function = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_generateNewPreviewThumbnail');
//                     return post_generateNewPreviewThumbnail_function({
//                         docId: appData.extracted_url_id
//                     })

//                 }).catch((error) => {
//                     // @liam - Added better error logging
//                     console.error("Error: " + error);
//                     show_loading_indicator_on_primary_bottom_bar(false, "Create your invite")
//                     $("#login_with_phone_number_verify_modal").modal("hide");
//                     $("#login_modal_verify_otc_button").html("Verify SMS Code");
//                     alert(error.message)

//                 })
//             }

//         } else {

//             if ($("#recipients_name_personalize_card").val() == "") {
//                 $("#recipients_name_personalize_card").addClass("is-invalid");
//             } else {

//                 $("#recipients_name_personalize_card").removeClass("is-invalid");

//                 appData.occasion = $("#occasion_choose_prompt_dropdown").val()
//                 appData.recipient_name = $("#recipients_name_personalize_card").val()

//                 var post_generateBlankGreetingFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_generateBlankGreeting');
//                 post_generateBlankGreetingFunction({
//                     tag_id: appData.extracted_url_id,
//                     recipient_name: appData.recipient_name,
//                     user_selected_occasion: appData.occasion
//                 }).then(function (result) {

//                     $("#login_with_phone_number_verify_modal").modal("hide");
//                     $("#login_modal_verify_otc_button").html("Verify SMS Code");

//                     if (result == "create-limit-reached") {
//                         throw new Error('You have reached your limit on the number of greetings or invites you can create in 24 hours.');
//                     } else {
//                         appData.extracted_url_id = result.data.id
//                         window.history.pushState({}, document.title, "/i/" + appData.extracted_url_id);

//                         appData.expression_array = result.data.expression_array
//                         appData.occasion = result.data.occasion
//                         appData.is_deletable_code = result.data.is_deletable_code
//                         appData.recipient_name = result.data.recipient_name
//                         appData.is_saved_greeting = result.data.user_saved_card
//                         appData.sender_name = result.data.sender_name
//                         appData.has_recipient_claimed = result.data.has_recipient_claimed
//                         appData.can_this_user_modify_greeting = result.data.canThisUserWriteToCurrentTag
//                         appData.cardType = result.data.cardType
//                         appData.background_image = result.data.background_image
//                         appData.font_type = result.data.font_type
//                         appData.font_theme = result.data.font_theme
//                         appData.is_type_invite = false
//                     }

//                 }).then(function () {

//                     drawGreetie()

//                     var post_generateNewPreviewThumbnail_function = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_generateNewPreviewThumbnail');
//                     return post_generateNewPreviewThumbnail_function({
//                         docId: appData.extracted_url_id
//                     })

//                 }).catch((error) => {
//                     // @liam - Added better error logging
//                     console.error("Error: " + error);
//                     $("#login_with_phone_number_verify_modal").modal("hide");
//                     $("#login_modal_verify_otc_button").html("Verify SMS Code");
//                     alert(error.message)
//                     // alert(error.message)
//                 })
//             }
//         }



//     } else {
//         show_onboarding_login("generate_new_greetie_code")
//     }

//     // }
// }
