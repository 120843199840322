window.show_onboarding_prompt_instructions = function () {
    appData.current_user_view = "select_prompt"
    draw_prompt_instructions()
}

window.draw_prompt_instructions = function () {

    hide_allViews()
    $('#prompt_select_container').html("")
    $("#choose_prompt").removeClass("d-none")

    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").addClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")
    $("#bottom_action_bar_small_secondary_link").removeClass("d-none")

    var promptType;
    if(appData.is_type_invite){
        promptType = "event_invite"
        $("#type_of_prompt_title_string").html("invite")
    }else{
        promptType = $("#occasion_choose_prompt_dropdown").val()
        $("#type_of_prompt_title_string").html(occasionTranslator($("#occasion_choose_prompt_dropdown").val()))
    }
        
    var counter = 0;
    prompt_options[promptType].forEach(function (item) {
        var template = $($('#prompt_item_template').html());
        $(template).find(".prompt_item_button").attr("prompt-int", counter)
        $(template).find(".prompt_title").html(item)
        $('#prompt_select_container').append(template);
        counter = counter + 1
    })

    $(".prompt_item_button").each(function () {
        $(this).on('click', function (e) {
            e.preventDefault();
            selected_prompt = $(this).attr("prompt-int")
            appData.onboarding_video_occasion_type = prompt_options[$("#occasion_choose_prompt_dropdown").val()][selected_prompt]
            $("#selected-prompt-in-popup").html(appData.onboarding_video_occasion_type)
            show_prompt_instuctions()
        })
    });
}

window.fileUploaded = function (input) {

    const maxFileSize = 500 * 1024 * 1024;
    if (input.files[0].size > maxFileSize) {
        alert("This file is too big. Select a smaller video file.")
    } else {
        appData.videoFile = input.files[0];
        appData.videoUrl = URL.createObjectURL(appData.videoFile);
        $('#preview_upload_video_player').attr("src", appData.videoUrl)
        document.getElementById('preview_upload_video_player').load()

        if (appData.current_user_view == "select_prompt" || appData.current_user_view == "select_prompt_edit") {
            hide_prompt_instuctions()
        } else {
            // In case the user went back and didn't want a prompt
            appData.onboarding_video_occasion_type = ""
        }
        if (appData.current_user_view == "select_prompt_edit") {
            appData.current_user_view = "confirm_upload_edit"
            draw_ready_to_upload()
            return
        }
        show_onboarding_ready_to_upload()
    }
}

window.show_prompt_instuctions = function () {
    $("#how_to_record_prompt_modal").modal("show");
}

window.hide_prompt_instuctions = function () {
    $("#how_to_record_prompt_modal").modal("hide");
}

window.occasionTranslator = function (string) {
    if (string == "thinking_of_you") {
        return "Thinking of You";
    }
    if (string == "birthday") {
        return "Happy Birthday";
    }
    if (string == "sympathy") {
        return "Sympathy";
    }
    if (string == "wedding") {
        return "Cheers to Your Love";
    }
    if (string == "anniversary") {
        return "Happy Anniversary";
    }
    if (string == "get_well_soon") {
        return "Get Well Soon";
    }
    if (string == "congratulations") {
        return "Congratulations";
    }
    if (string == "thank_you") {
        return "Thank You";
    }
    if (string == "just_because") {
        return "Just Because";
    }
    if (string == "new_baby") {
        return "Your New Baby";
    }
    if (string == "graduation") {
        return "Happy Graduation";
    }
    if (string == "encouragement") {
        return "You've Got This";
    }
    if (string == "apology") {
        return "I'm Sorry";
    }
    if (string == "christmas") {
        return "Merry Christmas";
    }
    if (string == "hanukkah") {
        return "Happy Hanukkah";
    }
    if (string == "thanks_for_invite") {
        return "Thanks for the invite";
    }

    // If string doesn't match any of the above occasions, return the original string.
    return string;
}