window.show_onboarding_choose_font = function () {

    hide_allViews()
    $("#choose_font").removeClass("d-none")

    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").addClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")

    appData.current_user_view = "choose_font"

    scroll.animateScroll(0);

    draw_choose_font_options()
    tempData.vibe_selection_font_color = "light"

    $("#choose_font").on("change", ':checkbox', function () {
        if (tempData.vibe_selection_font_color == "light") {
            tempData.vibe_selection_font_color = "dark"
        } else {
            tempData.vibe_selection_font_color = "light"
        }
        draw_choose_font_options()
    })

}


window.draw_choose_font_options = function () {

    $('#font_items_collection').html("");

    var selectable_fonts = [
        "vibe_woodward",
        "vibe_recoleta",
        "vibe_phosphate",
        "vibe_pacifico",
        "vibe_hotrushsans",
        "vibe_flow",
        "vibe_bangers",
        "Outfit-Black"
    ]

    selectable_fonts.forEach(function (item) {
        var template = $($('#font_item_template').html());
        $(template).find(".preview_vibe_font_title").css('font-family', item);
        $(template).find(".preview_vibe_font_title").addClass(item + "_preview");

        if (tempData.vibe_selection_font_color == "dark") {
            $(template).find(".preview_vibe_font_title").addClass("invert_font_shadow");
        }

        $(template).find(".font_item").attr("font-id", item)
        $(template).find(".font_item").css('background-image', "url(" + appData.vibe_background_selection_thumbnail + ")");

        if (item == "vibe_phosphate") {
            $(template).find(".preview_vibe_font_subtitle").addClass("text-uppercase")
            $(template).find(".preview_vibe_font_title").addClass("text-uppercase")
        }

        $('#font_items_collection').append(template);
    })

    // Choose a vibe and move to expression
    $(".font_item").each(function () {
        $(this).on('click', function (e) {
            e.preventDefault();
            $(".font_item").each(function () {
                $(this).removeClass("color-border")
            })

            $(this).addClass("color-border")
            appData.vibe_font_selection = $(this).attr("font-id")

            $("#bottom_action_bar_button_primary").removeClass("d-none")
            $("#bottom_action_bar_button_primary").html("Save")
        })
    });

}


window.update_tag_font = function () {

    window.show_loading_indicator_on_primary_bottom_bar(true)

    // Loading stuff now...
    var post_greetingVibe = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_greetingVibe');
    post_greetingVibe({
        vibe_font: appData.vibe_font_selection,
        vibe_background: appData.vibe_background_selection,
        vibe_font_theme: tempData.vibe_selection_font_color,
        tag_id: appData.extracted_url_id

    }).then(function (result) {

        // This can run async
        var post_generateNewPreviewThumbnail_function = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_generateNewPreviewThumbnail');
        post_generateNewPreviewThumbnail_function({
            docId: appData.extracted_url_id
        })

        show_loading_indicator_on_primary_bottom_bar(true, "Next")
        pull_recent_greetie_details()
        
    }).catch((error) => {
        alert("Something went wrong")
        window.show_loading_indicator_on_primary_bottom_bar(false, "Save")
    });




}
