// Attach a click event handler to the elements with the class 'rvsp_invite_bottom_bar_button'
$('.rvsp_invite_bottom_bar_button').on('click', function () {
    // Check if the user is logged in
    if (appData.user_logged_in) {
        // If the user is logged in, proceed to change the RSVP status
        change_rsvp($(this));
    } else {
        // If the user is not logged in, store the clicked element in 'tempData.thisContainer'
        tempData.thisContainer = $(this);

        // Show the onboarding login screen with the 'rsvp_change' parameter
        show_onboarding_login("rsvp_change");

        // Return early to prevent further execution
        return;
    }
});


$(".rsvp-response-add-greeting").on('click', function () {
    const selectedAction = $(this).data('selected-action');

    // Show spinner and disable button
    $(this).find('.spinner-border').removeClass('d-none');
    $(this).find('img, h4').addClass('d-none');
    $(this).prop('disabled', true);

    var post_generateBlankGreetingFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_generateBlankGreeting');
    return post_generateBlankGreetingFunction({
        tag_id: undefined,
        recipient_name: appData.sender_name,
        user_selected_occasion: "thanks_for_invite",
        is_reply_greeting: true,
        selected_action: selectedAction,
        reply_greeting_id: appData.extracted_url_id
    }).then(function (result) {
        var returnedData = result.data

        $("#invite-greeting-rsvp-reply-options-container").addClass("d-none")
        $("#invite-greeting-rsvp-reply-saved-container").removeClass("d-none")
        $("#invite-greeting-rsvp-reply-link").attr("href", window.location.origin + "/i/" + returnedData.id)

        try {
            window.location.href = window.location.origin + "/item/?selected-option=" + selectedAction + "&id=" + returnedData.id;
        } catch (error) {
            console.error('Error navigating to the new URL:', error);
        }

        // Hide spinner, show image and text, and enable button
        $(".rsvp-response-add-greeting").find('.spinner-border').addClass('d-none');
        $(".rsvp-response-add-greeting").find('img, h4').removeClass('d-none');
        $(".rsvp-response-add-greeting").prop('disabled', false);

    }).catch((e) => {
        console.log(e);
        alert(e.message)

        // Hide spinner, show image and text, and enable button
        $(".rsvp-response-add-greeting").find('.spinner-border').addClass('d-none');
        $(".rsvp-response-add-greeting").find('img, h4').removeClass('d-none');
        $(".rsvp-response-add-greeting").prop('disabled', false);
    })
});



/**
 * Handles the "back to invite" button click event.
 * Attempts to focus the opener window and navigate to the invite URL.
 * @param {Event} e - The click event.
 */
$("#back_to_invite_bottom_bar_button").on('click', function (e) {
    // Prevent default behavior (i.e. following the link).
    e.preventDefault();

    try {
        // Attempt to focus the opener window (the window that opened this one).
        window.opener.focus();
    } catch (error) {
        // If an error occurs, log it to the console.
        console.error('Error focusing opener window:', error);
    }

    try {
        // Attempt to navigate to the invite URL.
        window.location.href = "/item/?id=" + appData.back_to_invite_id;
    } catch (error) {
        // If an error occurs, log it to the console.
        console.error('Error navigating to the new URL:', error);
    }
});




window.change_rsvp = function (thisContext) {
    if (thisContext.data('response') != appData.user_rsvp_status) {
        thisContext.removeClass("special-secondary-btn")
        var thisText = thisContext.html()

        var $thisButton = thisContext;
        var intended_rsvp_status = thisContext.data('response')
        thisContext.html(
            `<div class="spinner-border spinner-border-sm me-1" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>`
        );

        var post_changeRsvpStatusFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_changeRsvpStatus');
        post_changeRsvpStatusFunction({
            docId: appData.extracted_url_id,
            rsvp_status: intended_rsvp_status
        }).then(function () {
            $("#complete_rsvp_modal").modal("show");

            if (intended_rsvp_status == "yes") {
                $(".complete_rsvp_modal_subtitle").html("Congrats")
                $(".complete_rsvp_modal_title").html("You're going")
            }
            if (intended_rsvp_status == "maybe") {
                $(".complete_rsvp_modal_subtitle").html("You can still change your mind")
                $(".complete_rsvp_modal_title").html("You're a maybe")
            }
            if (intended_rsvp_status == "no") {
                $(".complete_rsvp_modal_subtitle").html("That sucks")
                $(".complete_rsvp_modal_title").html("You aren't going")
            }

            appData.user_rsvp_status = $thisButton.data('response');
            $thisButton.html(thisText);
            show_rsvp_complete_invite_bottom_bar()
            
            show_loading_indicator_on_primary_bottom_bar(true, "Next")
            pull_recent_greetie_details()

        }).catch((e) => {
            alert(e.message)
        })
    } else {
        // Just make the bottom bar go back. 
        // No change needed
        show_rsvp_complete_invite_bottom_bar()
    }
}


$('#rsvp_complete_event_change_rsvp_bottom_bar_button').on('click', function () {
    show_rsvp_invite_bottom_bar()
})





$('#open_plus_one_modal').on('click', function () {
    $("#complete_rsvp_modal").modal("hide");
    $("#invite_plus_ones_rsvp_modal").modal("show");
})

$('.close_plus_one_modal').on('click', function () {
    $("#complete_rsvp_modal").modal("show");
    $("#invite_plus_ones_rsvp_modal").modal("hide");

    // Clear out the plus one list...
    var template = $($('#plus_one_guest_container_template').html());
    $('.plus_one_container').html(template);
})

$('#add_plus_one_button').on('click', function () {
    var template = $($('#plus_one_guest_container_template').html());
    $('.plus_one_container').append(template);
})

/**
 * jQuery event listener for the 'plus_one_send_invites_button' click event
 */
$('#plus_one_send_invites_button').on('click', function () {
    const spinnerHtml = `<div class="spinner-border spinner-border-sm me-1" role="status"><span class="visually-hidden">Loading...</span></div>`;

    let validData = [];

    // Extract valid guest data from the form
    $('.plus_one_guest_container').each(function () {
        const container = $(this);
        const guestData = extractGuestData(container);
        if (guestData) validData.push(guestData);
    });

    // Send invites if the data is valid
    if (validData.length > 0) {
        $(this).html(spinnerHtml);
        sendInvites(validData)
            .then((result) => {
                alert(result.data.message)
                showCompleteModal();
                clearGuestList();
                restoreButton(this);  // Restoring button to original state
            })
            .catch((error) => {
                console.error(error);
                alert(error.message);
                restoreButton(this);  // Restoring button to original state
            });
    }
});

/**
 * Extracts and validates guest data from the given container.
 *
 * @param {object} container - The container from which to extract the data.
 * @return {Array} - The validated guest data or null if the data is not valid.
 */
function extractGuestData(container) {
    const phoneNumber = "+1" + container.find('#guest_phone_number').val();
    const firstNameInput = container.find('.guest_first_name');
    const phoneNumberInput = container.find('#guest_phone_number');
    let validForm = firstNameInput.val() !== "";

    if (validForm && libphonenumber.isValidPhoneNumber(phoneNumber)) {
        let phoneNumberType = libphonenumber.parsePhoneNumber(phoneNumber).getType();
        if (["MOBILE", "FIXED_LINE_OR_MOBILE", "PERSONAL_NUMBER"].includes(phoneNumberType)) {
            console.log("This one is valid. Cool.");
            return [firstNameInput.val(), phoneNumber];
        }
    }

    // Show error feedback if the form data is not valid
    firstNameInput.toggleClass("is-invalid", !validForm);
    phoneNumberInput.toggleClass("is-invalid", !validForm);

    return null;
}

/**
 * Calls a Firebase function to send invite SMSs.
 *
 * @param {Array} validData - The validated data to send with the invites.
 * @return {Promise} - A promise that resolves with the result of the Firebase function call.
 */
function sendInvites(validData) {
    const sendInviteSMSFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_sendSMSInvite');
    return sendInviteSMSFunction({
        docId: appData.extracted_url_id,
        inviteData: validData
    });
}

/**
 * Shows the complete RSVP modal and hides the invite plus ones RSVP modal.
 */
function showCompleteModal() {
    $("#complete_rsvp_modal").modal("show");
    $("#invite_plus_ones_rsvp_modal").modal("hide");
}

/**
 * Clears the plus one guest list.
 */
function clearGuestList() {
    const template = $($('#plus_one_guest_container_template').html());
    $('.plus_one_container').html(template);
}

/**
 * Restores the 'plus_one_send_invites_button' to its original state.
 * @param {object} buttonElement - The button to restore.
 */
function restoreButton(buttonElement) {
    $(buttonElement).html('Original State');  // Adjust this according to your original button state
}






window.open_rsvp_full_list_modal = function () {
    $("#see_all_rsvps_modal").modal("show");
    display_rsvp_list_data_in_modal("yes")
}

$("#see_all_rsvps_modal_segmented_control").on('click', '.segmented_control_button_not_active', function (e) {
    e.preventDefault();
    // Remove the active class from all buttons and add the not_active class
    $(".segmented_control_button_active").removeClass("segmented_control_button_active").addClass("segmented_control_button_not_active");
    // Add the active class to the clicked button and remove the not_active class
    $(this).addClass("segmented_control_button_active").removeClass("segmented_control_button_not_active");

    // Find the index of the clicked button
    const buttonIndex = $("#see_all_rsvps_modal_segmented_control .col").index(this);

    if (buttonIndex == 0) {
        display_rsvp_list_data_in_modal("yes")
    } else if (buttonIndex == 1) {
        display_rsvp_list_data_in_modal("maybe")
    } else if (buttonIndex == 2) {
        display_rsvp_list_data_in_modal("no")
    }
});


// Function to display RSVP list data in a modal
function display_rsvp_list_data_in_modal(rsvp) {
    // Clear the modal list container
    $('.see_all_rsvps_modal_list_container').empty();

    // Add the loading template
    var template_loading = $($('#invite_rsvp_list_loading').html());
    $('.see_all_rsvps_modal_list_container').append(template_loading);

    // Call the Firebase function to get all RSVPs
    var get_allRsvpsFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_allRsvps');
    get_allRsvpsFunction({
        docId: appData.extracted_url_id,
        selected_rsvp: rsvp
    }).then(function (result) {
        // Clear the modal list container
        $('.see_all_rsvps_modal_list_container').empty();

        // Initialize an empty string for the RSVP list HTML
        var rsvp_html = "";

        // Iterate through each document in the result data
        result.data.forEach(doc => {
            // Get the RSVP list element template
            var template_sub = $($('#expression_array_rsvp_list_elem').html());

            // Update the template with the document data
            $(template_sub).find(".first_name_rsvp").text(doc["first_name"]);
            $(template_sub).find(".rsvp_status").text(returnRsvp(doc["rsvp_status"]));
            $(template_sub).find(".time_updated").text(timeAgo(new Date(parseInt(doc["updated"]._seconds, 10) * 1000)));

            // Show or hide the special-container based on greeting_id
            if (doc["greeting_id"] && doc["greeting_id"].length > 0) {
                $(template_sub).find(".special-container").removeClass("d-none");
                $(template_sub).find(".invite-attached-clipjoy-string").html(doc["first_name"] + " added " + correctStringForTypeOfRSVP(doc["expected_expression"]))
                $(template_sub).find(".special-container").attr("href", window.location.origin + "/i/" + doc["greeting_id"])
            } else {
                $(template_sub).find(".special-container").addClass("d-none");
            }

            // Append the updated template to the RSVP list HTML
            rsvp_html += template_sub.html();
        });

        // If the RSVP list HTML is empty, display the empty RSVP list template
        if (rsvp_html === "") {
            var template_sub = $($('#invite_rsvp_list_empty').html());
            rsvp_html = template_sub;
        }

        // Append the RSVP list HTML to the modal list container
        $('.see_all_rsvps_modal_list_container').append(rsvp_html);

    }).catch((e) => {
        console.log(e);
        alert(e.message);
    });
}


/**
 * This function takes in a string argument called `type`, which represents the type of RSVP that a user has made. 
 * Based on the input value of `type`, the function returns a specific string that describes the RSVP.
 *
 * @param {string} type - A string representing the type of RSVP.
 * @returns {string} A string that describes the RSVP.
 */
window.correctStringForTypeOfRSVP = function (type) {
    // Check if the input type matches any of the predefined cases.
    if (type == "photo") {
        return "a few photos"
    }
    if (type == "gif") {
        return "a gif"
    }
    if (type == "poem") {
        return "an AI poem"
    }
    if (type == "video") {
        return "a video"
    }
    if (type == "gift") {
        return "a real gift"
    }
    if (type == "note") {
        return "a thoughtful note"
    }

    // If the input type is not recognized, return a default message.
    return "Invalid RSVP type";
}




function addToCalendar(title, startTime, location, description, calendarService) {
    // Calculate the end time as 2 hours after the start time.
    const endTime = new Date(startTime.getTime() + (2 * 60 * 60 * 1000));

    // Format the start and end times as UTC strings in the format YYYYMMDDTHHmmssZ.
    const formatTime = (date) => date.toISOString().replace(/-|:|\.\d+/g, '');
    const start = formatTime(startTime);
    const end = formatTime(endTime);


    var firstDescription = "See details: https://cjy.us/i/" + appData.extracted_url_id;
    for (var i = 0; i < appData.expression_array.length; i++) {
        var doc = appData.expression_array[i];
        if (doc["type"] == "note") {
            firstDescription = String(doc["text"]) + " - Details: https://cjy.us/i/" + appData.extracted_url_id;
            break; // This will break out of the loop once a match is found
        }
    }


    var firstLocation = "https://cjy.us/i/" + appData.extracted_url_id;
    for (var i = 0; i < appData.expression_array.length; i++) {
        var doc = appData.expression_array[i];
        if (doc["type"] == "location") {
            firstLocation = doc["text"];
            break; // This will break out of the loop once a match is found
        }
    }

    // Determine the URL based on the selected calendar service.
    let url;
    switch (calendarService) {
        case 'apple':
            const iCalFile = `BEGIN:VCALENDAR\r\nVERSION:2.0\r\nBEGIN:VEVENT\r\nSUMMARY:${title}\r\nDTSTART:${start}\r\nDTEND:${end}\r\nLOCATION:${firstLocation}\r\nDESCRIPTION:${firstDescription}\r\nEND:VEVENT\r\nEND:VCALENDAR`;

            const fileName = `${encodeURIComponent(title)}.ics`;
            const mimeType = 'text/calendar';

            // Create a blob from the iCal file contents.
            const blob = new Blob([iCalFile], { type: mimeType });

            // Create a URL for the blob and create a link element to prompt the user to download it.
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);

            // Click the link to prompt the user to download the file.
            link.click();

            // Clean up by removing the link and revoking the URL.
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);

            return;
        case 'google':
            url = `http://www.google.com/calendar/event?action=TEMPLATE&text=${title}&dates=${start}/${end}&details=${firstDescription}&location=${firstLocation}`;
            break;
        case 'outlook':
            url = `https://outlook.live.com/calendar/0/view/month?subject=${encodeURIComponent(title)}&startdt=${encodeURIComponent(start)}&enddt=${encodeURIComponent(end)}&location=${encodeURIComponent(firstLocation)}&body=${encodeURIComponent(firstDescription)}`;
            break;
        default:
            console.error('Invalid calendar service:', calendarService);
            return;
    }

    // Open a new tab or window with the appropriate URL.
    window.open(url, '_blank');
}



$(".add_to_calendar_button_modal").on('click', function (e) {
    // Prevent default behavior (i.e. following the link).
    e.preventDefault();
    if (!appData.user_logged_in) {
        show_onboarding_login("see_invite_details")
    } else if (appData.invite_date_time != undefined) {
        $("#addToCalendarModal").modal("show")
    } else {
        alert("The date and time has not been set yet. If you’re the host of this event, set the time. If you aren’t, tell the host to get organized.")
    }
})



// Set up event listeners for the calendar service icons.
const appleCalendarIcon = document.querySelector('[data-calendar-service="apple"]');
if (appleCalendarIcon) {
    appleCalendarIcon.addEventListener('click', (event) => {
        event.preventDefault();
        addToCalendar(appData.invite_name, appData.invite_date_time, 'Event Location', 'Event Description', 'apple');
    });
}

const googleCalendarIcon = document.querySelector('[data-calendar-service="google"]');
if (googleCalendarIcon) {
    googleCalendarIcon.addEventListener('click', (event) => {
        event.preventDefault();
        addToCalendar(appData.invite_name, appData.invite_date_time, 'Event Location', 'Event Description', 'google');
    });
}

const outlookCalendarIcon = document.querySelector('[data-calendar-service="outlook"]');
if (outlookCalendarIcon) {
    outlookCalendarIcon.addEventListener('click', (event) => {
        event.preventDefault();
        addToCalendar(appData.invite_name, appData.invite_date_time, 'Event Location', 'Event Description', 'outlook');
    });
}
