
// colors
// celebrate
// fit
// cute_things
// trendy_vibes
// travel

window.init_data_vibes = function () {

    appData.background_vibes_data = [
        {
            "file": "001",
            "tag": ["colors"]
        },
        {
            "file": "002",
            "tag": ["colors"]
        },
        {
            "file": "003",
            "tag": ["colors"]
        },
        {
            "file": "004",
            "tag": ["colors"]
        },
        {
            "file": "005",
            "tag": ["colors"]
        },
        {
            "file": "006",
            "tag": ["colors"]
        },
        {
            "file": "007",
            "tag": ["colors"]
        },
        {
            "file": "008",
            "tag": ["colors"]
        },


        {
            "file": "009",
            "tag": ["celebrate"]
        },
        {
            "file": "010",
            "tag": ["celebrate"]
        },
        {
            "file": "011",
            "tag": ["celebrate"]
        },
        {
            "file": "012",
            "tag": ["celebrate"]
        },


        {
            "file": "013",
            "tag": ["fit"]
        },
        {
            "file": "014",
            "tag": ["fit"]
        },
        {
            "file": "015",
            "tag": ["fit"]
        },
        {
            "file": "016",
            "tag": ["fit"]
        },


        {
            "file": "017",
            "tag": ["cute_things"]
        },
        {
            "file": "018",
            "tag": ["cute_things"]
        },
        {
            "file": "019",
            "tag": ["cute_things"]
        },
        {
            "file": "020",
            "tag": ["cute_things"]
        },



        {
            "file": "021",
            "tag": ["trendy_vibes"]
        },
        {
            "file": "022",
            "tag": ["trendy_vibes"]
        },
        {
            "file": "023",
            "tag": ["trendy_vibes"]
        },
        {
            "file": "024",
            "tag": ["trendy_vibes"]
        },



        {
            "file": "025",
            "tag": ["travel"]
        },
        {
            "file": "026",
            "tag": ["travel"]
        },
        {
            "file": "027",
            "tag": ["trendy_vibes", "travel"]
        },



        {
            "file": "028",
            "tag": ["trendy_vibes"]
        },
        {
            "file": "029",
            "tag": ["cute_things"]
        },
        {
            "file": "030",
            "tag": ["trendy_vibes"]
        },
        {
            "file": "031",
            "tag": ["fit"]
        },
        {
            "file": "032",
            "tag": ["trendy_vibes"]
        },


        {
            "file": "034",
            "tag": ["trendy_vibes"]
        },
        {
            "file": "035",
            "tag": ["cute_things"]
        },
        {
            "file": "036",
            "tag": ["trendy_vibes"]
        },


        {
            "file": "037",
            "tag": ["travel"]
        },
        {
            "file": "038",
            "tag": ["travel"]
        },
        {
            "file": "039",
            "tag": ["travel"]
        }
    ]

}