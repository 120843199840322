
window.show_onboarding_uploading_shopping = function () {

    var previous_current_user_view = appData.current_user_view
    appData.current_user_view = "uploading_and_shop"
    appData.preventRedirectUploadingContent = true

    // RESET THE LOADER
    $(".saving_content_title").text("Saving your changes...")
    $(".saving_content_progress_loader").removeClass("bg-success")
    $(".saving_content_subtext").text("Don’t leave this page until this is complete.")
    $(".saving_content_progress_loader").css('width', '3%');

    if (previous_current_user_view == "choose_music" || previous_current_user_view == "choose_music_edit") {
        requestUploadUrlAndBeginFileUpload("photo")
    }
    if (previous_current_user_view == "confirm_upload" || previous_current_user_view == "confirm_upload_edit") {
        requestUploadUrlAndBeginFileUpload("video")
    }
    if (previous_current_user_view == "write_note" || previous_current_user_view == "write_note_review" || previous_current_user_view == "write_note_edit") {
        requestUploadUrlAndBeginFileUpload("note")
    }
    if (previous_current_user_view == "choose_location") {
        requestUploadUrlAndBeginFileUpload("location")
    }
    if (previous_current_user_view == "choose_ai_writing_review" || previous_current_user_view == "choose_ai_writing_review_edit") {
        requestUploadUrlAndBeginFileUpload("poem")
    }
    if (previous_current_user_view == "choose_gif" || previous_current_user_view == "choose_gif_edit") {
        requestUploadUrlAndBeginFileUpload("gif")
    }
    if (previous_current_user_view == "choose_expression_view") {
        beginProcessingCountdown()
    }

    draw_shopping()
}

window.draw_shopping = function () {
    hide_allViews()

    $("#uploading_shop_view").removeClass("d-none")


    $("#shop_header_post_items_after_here").addClass("d-none")
        $(".shop_loading_indicator").addClass("d-none")

    // if (!appData.is_type_invite) {
    //     $("#shop_header_post_items_after_here").removeClass("d-none")
    //     $(".shop_loading_indicator").removeClass("d-none")
    //     if (appData.current_user_view == "select_gift_edit") {
    //         $("#shop_header_title_default").removeClass("d-none")
    //         $("#shop_header_title_loading").addClass("d-none")
    //     } else {
    //         $("#shop_header_title_default").addClass("d-none")
    //         $("#shop_header_title_loading").removeClass("d-none")
    //     }
    //     load_store_content()
    // }else{
    //     $("#shop_header_post_items_after_here").addClass("d-none")
    //     $(".shop_loading_indicator").addClass("d-none")
    // }
    
    scroll.animateScroll(0);
}

window.load_store_content = function () {

    $(".shop_loading_indicator").removeClass("d-none")
    $(".shop_carousel").flickity('destroy')
    $('.shop_carousel').html("")
    $('.shop_items_main').html("")

    var get_allShopItems = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_allShopItems');
    get_allShopItems()
        .then((result) => {

            appData.shopData = result.data

            $(".shop_loading_indicator").addClass("d-none")

            appData.shopData.carousel.forEach(function (item) {
                var template = $($('#shop_carousel_template').html());
                $(template).find(".shop_item_name").html(item["name"]);
                $(template).find(".shop_item_headline").html(item["headline"]);
                if (item["isGiftCard"]) {
                    $(template).find(".shop_item_price").html("From $10")
                } else {
                    $(template).find(".shop_item_price").html("$" + String(item["price"] / 100))
                }
                $(template).find(".shop-item-id-class").attr("item-id", item["id"])
                $(template).find(".firstSlideshowImage").css('background-image', "url(" + item["img_url"] + ")");
                $('.shop_carousel').append(template);
            });

            appData.shopData.main.forEach(function (item) {
                var template = $($('#shop_item_template').html());
                $(template).find(".shop_item_name").html(item["name"])
                if (item["isGiftCard"]) {
                    $(template).find(".shop_item_price").html("From $10")
                } else {
                    $(template).find(".shop_item_price").html("$" + String(item["price"] / 100))
                }
                $(template).find(".shop-item-id-class").attr("item-id", item["id"])
                $(template).find(".firstSlideshowImage").css('background-image', "url(" + item["img_url"] + ")");
                $('.shop_items_main').append(template);
            });

            $('.shop_carousel').flickity({
                cellAlign: 'left',
                contain: true,
                autoPlay: true,
                prevNextButtons: false,
                pageDots: false,
                adaptiveHeight: true
            });

            $(".open-shop-modal-detail").each(function () {
                $(this).click(function () {
                    appData.gift_selected_id = $(this).find(".shop-item-id-class").attr("item-id")
                    var shopItem = pullShopItemDetails(appData.gift_selected_id)
                    $(".shop-item-modal-img").prop("src", shopItem["img_url"])
                    $(".shop-item-modal-long-desc").html(shopItem["desc"])
                    $(".shop-item-modal-name").html(shopItem["name"])
                    $("#shop-item-details-modal").modal("show");
                })
            });

        }).catch((e) => {
            console.log("Error with get_allShopItems");
            console.log(e);
        })
}

window.pullShopItemDetails = function (id) {
    var returnObject;
    appData.shopData.carousel.forEach(function (item) {
        if (item["id"] == id) {
            returnObject = item
        }
    })
    appData.shopData.main.forEach(function (item) {
        if (item["id"] == id) {
            returnObject = item
        }
    })
    return returnObject
}

window.calculate_processing_fee = function (val) {
    var returnVal = (Math.round((parseInt(val) * (0.029 + 0.02)) + 30) / 100)
    return returnVal
}

window.resetUploadingShoppingView = function () {
    $("#upload_file_type_title").html("Creating your greeting...")
    $("#upload_file_percentage_complete").html("0%")
    $("#upload_file_type_sub_text").html("Don’t leave this page until we've created your greeting.")
    $("#uploading_status_spinner_complete").addClass("d-none")
    $("#uploading_status_spinner_progress").removeClass("d-none")
}

$('.shop-item-modal-checkout-button').on('click', function (e) {
    e.preventDefault();
    $("#shop-item-details-modal").modal("hide");
    show_checkout_shop()
})

$('.shop-checkout-modal-back-to-details').on('click', function (e) {
    e.preventDefault();
    $("#shop-item-details-modal").modal("show");
    $("#checkout_shop_modal").modal("hide");
})


window.show_checkout_shop = function (selected_shop_item_id) {
    $("#checkout_shop_modal").modal("show");

    var shopItem = pullShopItemDetails(appData.gift_selected_id)

    if (shopItem["isGiftCard"]) {
        $(".gift_card_value_dropdown_container").removeClass("d-none")
        var select_html_price_options = ""
        shopItem["priceArray"].forEach(function (price_item) {
            select_html_price_options = select_html_price_options + '<option value="' + price_item + '">$' + (price_item / 100) + '</option>'
        })
        $("#gift_card_value_dropdown").html(select_html_price_options)
        appData.gift_selectedPrice = shopItem["priceArray"][0]
    } else {
        $(".gift_card_value_dropdown_container").addClass("d-none")
        appData.gift_selectedPrice = shopItem["price"]
    }

    $(".selected_shop_item_current_price").html("$" + (appData.gift_selectedPrice / 100).toFixed(2))
    $(".selected_shop_item_title").html(shopItem["name"])
    $(".selected_shop_item_current_processing_fee").html("$" + (calculate_processing_fee(appData.gift_selectedPrice)).toFixed(2))
    $(".selected_shop_item_current_total").html("$" + (parseFloat((calculate_processing_fee(appData.gift_selectedPrice) * 100) + appData.gift_selectedPrice) / 100).toFixed(2))
    gift_selected_gift_title = shopItem["name"]
    appData.selected_gift_final_total = ((calculate_processing_fee(appData.gift_selectedPrice) * 100) + appData.gift_selectedPrice)

    if (appData.debugPayments == true) {
        $(".selected_shop_item_title").html("(THIS WILL NOT CHARGE YOUR CARD) " + shopItem["name"])
    }

    var get_stripeClientSecret = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_stripeClientSecret');
    get_stripeClientSecret({
        recipient_phone: "+1" + String($(".gift_recipient_phone_number").val()),
        tagId: appData.extracted_url_id,
        selected_price: appData.gift_selectedPrice,
        debug_mode: appData.debugPayments,
        selected_item_id: appData.gift_selected_id,
    }).then(function (result) {
        var clientSecret = result.data.clientSecret
        const appearance = {
            theme: 'stripe',
        };
        elements = stripe.elements({ appearance, clientSecret });
        const paymentElementOptions = {
            layout: "accordion",
            paymentMethodOrder: ['apple_pay', 'google_pay', 'card']
        };
        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
    })

}


$("#gift_card_value_dropdown").change(function () {

    appData.gift_selectedPrice = parseInt(this.value)
    var processing_fee = calculate_processing_fee(appData.gift_selectedPrice)

    $(".selected_shop_item_current_price").html("$" + (appData.gift_selectedPrice / 100).toFixed(2))
    $(".selected_shop_item_current_processing_fee").html("$" + (processing_fee.toFixed(2)))
    $(".selected_shop_item_current_total").html("$" + (parseFloat((calculate_processing_fee(appData.gift_selectedPrice) * 100) + appData.gift_selectedPrice) / 100).toFixed(2))
    appData.selected_gift_final_total = ((calculate_processing_fee(appData.gift_selectedPrice) * 100) + appData.gift_selectedPrice)

    var get_stripeClientSecret = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_stripeClientSecret');
    get_stripeClientSecret({
        recipient_phone: "+1" + String($(".gift_recipient_phone_number").val()),
        tagId: appData.extracted_url_id,
        debug_mode: appData.debugPayments,
        selected_price: appData.gift_selectedPrice,
        selected_item_id: appData.gift_selected_id,
    }).then(function (result) {
        var clientSecret = result.data.clientSecret
        const appearance = {
            theme: 'stripe',
        };
        elements = stripe.elements({ appearance, clientSecret });
        const paymentElementOptions = {
            layout: "accordion",
            paymentMethodOrder: ['apple_pay', 'google_pay', 'card']
        };
        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
    })

});



$('.shop_checkout_modal_back_to_checkout').on('click', function (e) {
    e.preventDefault();
    $("#checkout_shop_modal_payment").modal("hide")
    $("#checkout_shop_modal").modal("show")
})

$('#shop_checkout_payment_button').on('click', function (e) {
    e.preventDefault();

    var formValid = true
    var phoneNumber = "+1" + $(".gift_recipient_phone_number").val()

    if (libphonenumber.isValidPhoneNumber(phoneNumber) && formValid) {
        if (["MOBILE", "FIXED_LINE_OR_MOBILE", "PERSONAL_NUMBER"].includes(libphonenumber.parsePhoneNumber(phoneNumber).getType())) {

            $("#shop_checkout_payment_button").html(
                `<div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Saving...</span>
                    </div>`
            );

            // We have to do this once before the payment is sent
            var get_stripeClientSecret = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_stripeClientSecret');
            get_stripeClientSecret({
                recipient_phone: "+1" + String($(".gift_recipient_phone_number").val()),
                tagId: appData.extracted_url_id,
                selected_price: appData.gift_selectedPrice,
                debug_mode: appData.debugPayments,
                selected_item_id: appData.gift_selected_id,
            }).then(function (result) {
                var clientSecret = result.data.clientSecret
                const appearance = {
                    theme: 'stripe',
                };
                elements = stripe.elements({ appearance, clientSecret });
                const paymentElementOptions = {
                    layout: "accordion",
                    paymentMethodOrder: ['apple_pay', 'google_pay', 'card']
                };
                const paymentElement = elements.create("payment", paymentElementOptions);
                paymentElement.mount("#payment-element");

                $("#checkout_shop_modal_payment").modal("show")
                $("#checkout_shop_modal").modal("hide")

                $("#shop_checkout_payment_button").html("Payment")
            })

        } else {
            formValid = false
        }
    } else {
        formValid = false
    }

    if (!formValid) {
        $(".gift_recipient_phone_number").addClass("is-invalid")
        $("#shop_checkout_payement_backup_button").html("Pay Now");
    } else {
        $(".gift_recipient_phone_number").removeClass("is-invalid")
    }

})




$('#shop_checkout_payment_paynow').on('click', function (e) {
    e.preventDefault();

    if (appData.preventRedirectUploadingContent) {
        // Show that alert
        $("#warning_content_still_uploading_cannot_checkout").removeClass("d-none")
    } else {
        $("#warning_content_still_uploading_cannot_checkout").addClass("d-none")

        $("#shop_checkout_payment_paynow").html(
            `<div class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">Saving...</span>
    </div>`
        );

        const { error } = stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.href
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            showMessage(error.message);
            $("#shop_checkout_payment_paynow").html("Pay now");
        } else {
            showMessage("An unexpected error occurred.");
        }

        $("#shop_checkout_payment_paynow").html("Pay now")
    }
})