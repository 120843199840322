import { renderGif } from '@giphy/js-components'
import { GiphyFetch } from '@giphy/js-fetch-api'
import anime from 'animejs/lib/anime.es.js';
import { format } from "date-fns"



window.show_latest_greetie = function () {
    hide_allViews()
    appData.current_user_view = "watch_greetie"

    $("#watch_greeting_view").removeClass("d-none")

    if (appData.background_image != "") {
        $(".bg-fcm-color-gradient").css('background-image', "url(" + appData.background_image + ")");
    }
}

window.show_welcome_modal = function () {
    $("#welcome_to_greetie_modal").modal("show");
}

$("#edit_vibe_button").on("click", function () {
    show_choose_vibe_background_view()
})

$("#confirm_recipient_of_greeting").on("click", function () {
    $("#confirm_recipient_of_greeting").html(
        `<div class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Saving...</span>
        </div>`
    );
    claimCardRecipient()
})

$("#edit_greeting_button").on("click", function () {
    if (appData.user_logged_in == true) {
        show_edit_modal()
    } else {
        show_onboarding_login("edit_greetie")
    }
})

$("#request_edit_greeting_button").on("click", function () {
    if (appData.user_logged_in == true) {
        $("#cannot_edit_greeting_modal").modal("show");
    } else {
        show_onboarding_login("check_if_edit_access")
    }
})

$(".reset_card_button").on("click", function () {
    show_delete_content_modal()
})

$(".delete_digital_greeting").on("click", function () {
    show_delete_code_modal()
})



/**
 * Event handler for the 'delete_this_greeting_button' click event.
 * It calls a Firebase function to delete a greeting based on an id, then updates the UI accordingly.
 * During the operation, the button is disabled and shows a loading spinner.
 * If the operation is successful, the page URL is updated and a modal is hidden.
 * In case of an error, an alert message is shown.
 */
$("#delete_this_greeting_button").on("click", function () {
    // Save the reference to the button for further use
    var $button = $(this);

    // Save the original button text to restore it later
    var originalButtonText = $button.html();

    // Disable the button and show a loading spinner
    $button.prop('disabled', true).html(
        `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>`
    );

    // Firebase function to delete the greeting
    var post_removeVideoFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_deleteGreeting');

    post_removeVideoFunction({
        docId: appData.extracted_url_id
    }).then(function (result) {
        // If the operation is successful
        if (result.data == "complete") {
            // Update the UI
            show_onboarding_type_and_details()
            window.location.href = window.location.origin + "/";
            // Reset the id to default
            appData.gift_unique_id = ""
            // Hide the modal
            $("#delete_code_modal").modal("hide");
        }
        // Restore the original button text and re-enable the button
        $button.html(originalButtonText).prop('disabled', false);
    }).catch((error) => {
        // If there's an error
        // Restore the original button text, re-enable the button and show an alert
        $button.html(originalButtonText).prop('disabled', false);
        alert("This code is owned by another account. Please contact support below if you believe this is a mistake.")
    });
})



/**
 * Event handler for the 'delete_and_reset_this_card_button' click event.
 * It calls a Firebase function to delete a greeting based on an id, then updates the UI accordingly.
 * During the operation, the button is disabled and shows a loading spinner.
 * If the operation is successful, the greeting is reset and a modal is hidden.
 * In case of an error, an alert message is shown.
 */
$("#delete_and_reset_this_card_button").on("click", function () {
    // Save the reference to the button for further use
    var $button = $(this);

    // Save the original button text to restore it later
    var originalButtonText = $button.html();

    // Disable the button and show a loading spinner
    $button.prop('disabled', true).html(
        `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>`
    );

    // Firebase function to delete the greeting
    var post_removeVideoFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_deleteGreeting');

    post_removeVideoFunction({
        docId: appData.extracted_url_id
    }).then(function (result) {
        // If the operation is successful
        if (result.data == "complete") {

            // So this should send the user back to the greeting page with the ID if it's a card right???
            window.location.href = window.location.origin + "/greeting/?id=" + appData.extracted_url_id;

            // Reset the id to default
            appData.gift_unique_id = ""
            // Hide the modal
            $("#remove_video_modal").modal("hide");
        }
        // Restore the original button text and re-enable the button
        $button.html(originalButtonText).prop('disabled', false);
    }).catch((error) => {
        // If there's an error
        // Restore the original button text, re-enable the button and show an alert
        $button.html(originalButtonText).prop('disabled', false);
        alert("This code is owned by another account. Please contact support below if you believe this is a mistake.")
    });
})



$('.general_link_copy_button').on('click', function (e) {
    e.preventDefault();
    copyToClipboard()
});

function copyToClipboard() {
    var textToCopy = "https://cjy.us/i/" + appData.extracted_url_id;
    navigator.clipboard.writeText(textToCopy)
        .then(() => {
            $('#copy-button').trigger('copied', ['Copied!']);
        })
        .catch((err) => {
            console.error('Failed to copy text: ', err);
            $('#copy-button').trigger('copied', ['Copy with Ctrl-c']);
        });
}

$('.general_share_button').on('click', function (e) {
    if (navigator.share) {
        navigator.share({
            url: "https://cjy.us/i/" + appData.extracted_url_id
        })
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing:', error));
    } else {
        copyToClipboard()
        alert('Copied the link your clipboard. Paste the link in an email or text message.');
    }
})




window.initialize_greetie = function () {
    // Time to pull card details from URL params
    var urlParams = parseURLParams(location.search);

    if (urlParams !== undefined && urlParams.hasOwnProperty("id")) {
        appData.extracted_url_id = urlParams["id"][0]
    }

    if (urlParams !== undefined && urlParams.hasOwnProperty("debug")) {
        // We don't charge people for anything here but we don't give them anythin
        // Either
        appData.debugPayments = true
        window.stripe = Stripe('pk_test_51L5yQbEtrK3oJeaBETxs7SzmdA7UsgzXe2ghcz4bZ6WVscKdrD6gprp3FQrTIRS7NifDE4RqYJI4nhidTJMwDCjB0071rjtVqn', {
            apiVersion: "2022-11-15",
        });
    } else {
        window.stripe = Stripe('pk_live_51L5yQbEtrK3oJeaB8CCVrmiwrEcFePEdsmRO6jxrQ6uiVp7KJM4XkpN2tP4Hskm9HLObZZrf6hIJizmbbcCjaMhp00z0f8Qvxp', {
            apiVersion: "2022-11-15",
        });
    }

    // if a user purchased something
    if (urlParams !== undefined && urlParams["redirect_status"] !== undefined && urlParams["redirect_status"][0] == "succeeded") {
        $("#show_success_gift_purchase_modal_only_store").modal("show");
        tempData.quickPauseFromPurchase = true
    }

    // if a user came from creating a reply, remove that from url
    if (urlParams !== undefined && urlParams["selected-option"] !== undefined) {
        show_edit_modal()
    }

    if (urlParams !== undefined && urlParams["create_type"] !== undefined && urlParams["create_type"][0] == "invite") {
        tempData.switchToInvite = true
        window.history.pushState({}, document.title, "/invite/");
    }

    if (appData.extracted_url_id != undefined) {
        window.history.pushState({}, document.title, "/i/" + appData.extracted_url_id);
    }

    $("#back_from_chrome_video_recording_modal").on("click", onBackFromChromeVideoClick);

    appData.loadingAnimation = bodymovin.loadAnimation({
        container: document.getElementById('loading_greetie_icon'),
        path: '/assets/img/icons8-loading-heart.json',
        renderer: 'svg',
        loop: true,
        autoplay: true,
        name: "Heart loading animation",
    });

    $('.shop_carousel').flickity({
        cellAlign: 'left',
        contain: true,
        autoPlay: true,
        prevNextButtons: false,
        pageDots: false,
        adaptiveHeight: true
    });

    $('.general_link_copy_button').tooltip();

    show_loading_indicator_on_primary_bottom_bar(true, "Next")
    pull_recent_greetie_details()

}


$(".show_success_gift_purchase_only_store_preview_greeting").on("click", function () {
    $(".show_success_gift_purchase_only_store_preview_greeting").html(
        `<div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>`
    );

    pull_recent_greetie_details()
})


window.pull_recent_greetie_details = function () {

    init_loading_bg()

    if (tempData.quickPauseFromPurchase == true) {
        tempData.quickPauseFromPurchase = false
        setTimeout(function () {
            pullGreetingData()
        }, 3000);
    } else {
        // This is the case that we don't have a code. Show the begining.
        if (appData.extracted_url_id === undefined) {
            console.log("State: No code present, user will create a code");
            show_onboarding_type_and_details()
            removeLoadingIndicatorShade()

            if (tempData.switchToInvite) {
                tempData.switchToInvite = false
                switchToInviteOption();
            }

        } else {
            pullGreetingData()
        }
    }
}


let countdownInterval;

function perpetualCountdown(targetDate) {
    // Clear any existing countdown
    if (countdownInterval) {
        clearInterval(countdownInterval);
    }

    // Parse the target date
    let target = new Date(targetDate);
    let now = new Date();

    // Adjust the target date based on the rules
    const daysPast = Math.floor((now - target) / (1000 * 60 * 60 * 24));

    if (daysPast < 20 && daysPast >= 0) {
        target.setDate(target.getDate() + 20);
    } else if (daysPast >= 20) {
        target = new Date();
        target.setHours(target.getHours() + 24);
    }

    countdownInterval = setInterval(() => {
        now = new Date();
        let diff = target - now;

        // If the countdown has reached zero, add 24 hours to the target and continue
        if (diff <= 0) {
            target.setHours(target.getHours() + 24);
            diff = target - now;
        }

        // const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        $(".countdown_to_expire_timer").html(`Only ${hours}h ${minutes}m ${seconds}s Left`)
    }, 1000);
}





function pullGreetingData() {
    var get_giftCodeDetails = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_giftCodeDetails');
    get_giftCodeDetails({
        docId: appData.extracted_url_id
    }).then(function (result) {
        console.log(result);

        if (!result.data.is_card_claimed && result.data.canThisUserWriteToCurrentTag) {

            window.location.href = window.location.origin + "/greeting/?id=" + appData.extracted_url_id;

        } else {



            appData.expression_array = result.data.expression_array
            appData.occasion = result.data.occasion
            appData.is_deletable_code = result.data.is_deletable_code
            appData.recipient_name = result.data.recipient_name
            appData.is_saved_greeting = result.data.user_saved_card
            appData.sender_name = result.data.sender_name
            appData.has_recipient_claimed = result.data.has_recipient_claimed
            appData.can_this_user_modify_greeting = result.data.canThisUserWriteToCurrentTag
            appData.cardType = result.data.cardType
            appData.background_image = result.data.background_image
            appData.font_type = result.data.font_type
            appData.font_theme = result.data.font_theme
            appData.is_reply_greeting = result.data.is_reply_greeting

            // This is if THIS USER has an greeting linked to their RSVP inorder to go back and edit that rsvp
            // and/or prevent the user from making 100 more of them for no reason.
            appData.user_rsvp_greeting_id = result.data.user_rsvp_greeting_id

            // This is used for greetings which are linked to invite. When we need to take the user back,
            // we use this URL. 
            appData.back_to_invite_id = result.data.back_to_invite_id

            appData.has_date_time_set = result.data.has_date_time_set
            if (appData.has_date_time_set) {
                appData.invite_date_time = new Date(parseInt(result.data.invite_date_time._seconds, 10) * 1000)
            }

            appData.invite_name = result.data.invite_name
            appData.is_type_invite = result.data.is_type_invite

            appData.is_user_rsvpd = result.data.is_user_rsvpd
            appData.rsvp_array = result.data.rsvp_array
            appData.user_rsvp_status = result.data.user_rsvp_status

            drawGreetie()
        }

    }).catch((error) => {

        removeLoadingIndicatorShade()
        $("#greeting_not_found_modal").modal("show")

        console.error("An error occurred while waiting for Promises:", error);
    });
}

const replaceGreetingWithEvent = () => {
    const greetingInstances = document.getElementsByClassName("page-type");
    Array.from(greetingInstances).forEach(greetingInstance => {
        const spanText = greetingInstance.textContent || greetingInstance.innerText;
        if (spanText.charAt(0) === spanText.charAt(0).toUpperCase()) {
            // currently Greeting is capitalized
            greetingInstance.textContent = "Event";
        } else {
            // currently greeting is lowercase
            greetingInstance.textContent = "event";
        }
    });
}


window.drawGreetie = function () {



    $(".show_success_gift_purchase_only_store_preview_greeting").html("See your Greeting");
    resetUploadingShoppingView();

    appData.gift_unique_id = "" // Reset this to default
    $(".general_link_field").val("https://cjy.us/i/" + appData.extracted_url_id)

    if (appData.can_this_user_modify_greeting) {
        $(".watch_greetie_modify_options_container").removeClass("d-none")
        $("#request_edit_greeting_button").addClass("d-none")
    } else {
        $(".watch_greetie_modify_options_container").addClass("d-none")
        $("#request_edit_greeting_button").removeClass("d-none")
    }

    if (appData.is_deletable_code) {
        $(".reset_card_button").addClass("d-none")
        $(".delete_digital_greeting").removeClass("d-none")
    } else {
        $(".reset_card_button").removeClass("d-none")
        $(".delete_digital_greeting").addClass("d-none")
    }

    if (appData.is_type_invite) {

        // This is an invite
        $(".text_occasion_title").addClass("d-none")
        $(".sender_greeting_subtitle").addClass("d-none")

        $(".watch_greeting_invite_actions_container").removeClass("d-none")

        $(".greeting_or_invite_string").html("invite")

        $(".date_greeting_subtitle").removeClass("d-none")
        $(".time_greeting_subtitle").removeClass("d-none")
        $(".hosted_greeting_subtitle").removeClass("d-none")

        $(".hosted_greeting_subtitle").html("Hosted by " + appData.sender_name)
        if (appData.has_date_time_set) {
            $(".date_greeting_subtitle").html(format(appData.invite_date_time, "EEEE, MMM d"))
            $(".time_greeting_subtitle").html(format(appData.invite_date_time, "h:mma"))
        } else {
            $(".date_greeting_subtitle").html("in the future")
            $(".time_greeting_subtitle").html("time is TBD")
        }

        $("#greeting_settings_button").html('<i class="bi bi-gear-wide-connected"></i> Settings, title and time')
        $(".primary_greeting_title").html(appData.invite_name)

        replaceGreetingWithEvent();
        $("#edit_greeting_button .options-subtitle").text("Video, photos, gifs and more");

    } else {

        // This is a greeting
        $(".sender_greeting_subtitle").html("From " + appData.sender_name)
        $(".watch_greeting_invite_actions_container").addClass("d-none")

        $(".name_sender").each(function () {
            $(this).html(appData.sender_name);
        });
        $(".name_recipient").each(function () {
            $(this).html(appData.recipient_name);
        });
        $(".text_occasion").each(function () {
            $(this).html(occasionTranslator(appData.occasion));
        });

        $(".text_occasion_title").removeClass("d-none")
        $(".sender_greeting_subtitle").removeClass("d-none")

        $(".greeting_or_invite_string").html("digital greeting")

        $("#greeting_settings_button").html('<i class="bi bi-gear-wide-connected"></i> Settings, recipient and more')

        $(".date_greeting_subtitle").addClass("d-none")
        $(".time_greeting_subtitle").addClass("d-none")
        $(".hosted_greeting_subtitle").addClass("d-none")

    }

    var all_fonts = [
        "vibe_phosphate_title",
        "vibe_pacifico_title",
        "vibe_bangers_title",
        "Outfit-Black_title",
        "vibe_woodward_title",
        "vibe_recoleta_title",
        "vibe_flow_title",
        "vibe_hotrushsans_title"
    ]

    all_fonts.forEach(element => {
        $(".primary_greeting_title").removeClass(element)
    });
    $(".primary_greeting_title").addClass(appData.font_type + "_title")
    $(".primary_greeting_title").css("font-family", appData.font_type);

    if (appData.font_theme == "dark") {
        $(".primary_greeting_title").addClass("invert_font_shadow")
        $(".sender_greeting_subtitle").addClass("invert_font_shadow_sm")
        $(".text_occasion_title").addClass("invert_font_shadow_sm")
    } else {
        $(".primary_greeting_title").removeClass("invert_font_shadow")
        $(".sender_greeting_subtitle").removeClass("invert_font_shadow_sm")
        $(".text_occasion_title").removeClass("invert_font_shadow_sm")
    }

    $("#expression_array_container").html("")
    var expression_counter = 0

    var promiseChain = []

    appData.expression_array.forEach(async doc => {

        if (doc["type"] == "photos") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_photos').html());
                $(template).find(".firstSlideshowImage").css('background-image', "url(" + doc["files"][0] + ")");
                $(template).find("#dynamic-gallery-demo").attr("id", "dynamic-gallery-demo_" + doc["id"])
                $(template).attr("data-expression-id", doc["id"])
                $('#expression_array_container').append(template);
                var slideshowFiles = []
                doc["files"].forEach(imageSrc => {
                    slideshowFiles.push({
                        src: imageSrc,
                        thumb: imageSrc
                    })
                })
                const $dynamicGallery = document.getElementById("dynamic-gallery-demo_" + doc["id"]);
                const dynamicGallery = lightGallery($dynamicGallery, {
                    dynamic: true,
                    plugins: [lgAutoplay],
                    mode: 'lg-lollipop',
                    forceSlideShowAutoplay: true,
                    download: false,
                    slideShowAutoplay: true,
                    autoplayControls: false,
                    counter: false,
                    rotate: false,
                    dynamicEl: slideshowFiles,
                });
                $dynamicGallery.addEventListener('click', function () {
                    dynamicGallery.openGallery(0);
                });
                $dynamicGallery.addEventListener('lgBeforeOpen', () => {
                    if (doc["secondary_media"] != "") {
                        appData.musicSelection.forEach(function (musicItem) {
                            if (musicItem[2] == doc["secondary_media"]) {
                                appData.selectedMusicFile = new Audio(musicItem[3]);
                            }
                        });
                        appData.selectedMusicFile.play();
                    }
                });
                $dynamicGallery.addEventListener('lgBeforeClose', () => {
                    appData.selectedMusicFile.pause();
                });
                resolve()
            }))
        }

        if (doc["type"] == "video") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_video').html());
                $(template).find(".preview_watch_video_thumbnail").attr("src", doc["files"][0] + "#t=0.5")
                $('#expression_array_container').append(template);
                $(template).attr("data-expression-id", doc["id"])
                resolve()
            }))
        }


        if (doc["type"] == "note") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_note').html());
                $(template).attr("data-expression-id", doc["id"])
                $(template).find(".note_content_preview").html(doc["text"].trim())
                $('#expression_array_container').append(template);
                resolve()
            }))
        }

        if (doc["type"] == "location") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_location').html());
                var snapshotURL = doc["apple_maps_url"];
                $(template).find(".background_maps_image").css('background-image', "url('" + snapshotURL + "')");
                $(template).find(".location_street_name").html(doc["text"])
                $(template).attr("data-expression-id", doc["id"])

                if (doc["coordinates"] != undefined && doc["coordinates"] != null) {
                    const lat = doc["coordinates"][0];
                    const lng = doc["coordinates"][1];
                    const label = doc["text"];

                    var platformOS = bowser.getParser(window.navigator.userAgent).getOSName()

                    if (platformOS == "iOS") {
                        // Open apple maps
                        const appleMapsUrl = `maps://maps.apple.com/?ll=${lat},${lng}&q=${label}`;
                        $(template).find(".location_link").attr("href", appleMapsUrl)
                    } else {
                        const googleMapsUrl = `https://maps.google.com/maps?q=${lat},${lng}(${label})&z=15`;
                        $(template).find(".location_link").attr("href", googleMapsUrl)
                    }
                }

                if (!appData.user_logged_in && appData.is_type_invite) {
                    $(template).find(".location_link").addClass("d-none")
                }

                $('#expression_array_container').append(template);

                resolve();
            }));
        }

        if (doc["type"] == "poem") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_poem').html());
                $(template).attr("data-expression-id", doc["id"])
                $(template).find(".poem_content_preview").html(doc["text"].trim())
                $('#expression_array_container').append(template);
                resolve()
            }))
        }

        if (doc["type"] == "rsvp_list") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_rsvp_list').html());
                var rsvp_html = ""

                appData.rsvp_array.forEach(doc => {
                    var template_sub = $($('#expression_array_rsvp_list_elem').html());
                    $(template_sub).find(".first_name_rsvp").html(doc["first_name"])
                    $(template_sub).find(".rsvp_status").html(returnRsvp(doc["rsvp_status"]))
                    $(template_sub).find(".time_updated").html(timeAgo(new Date(parseInt(doc["updated"]._seconds, 10) * 1000)))

                    if (doc["greeting_id"] && doc["greeting_id"].length > 0) {
                        $(template_sub).find(".special-container").removeClass("d-none");
                        $(template_sub).find(".invite-attached-clipjoy-string").html(doc["first_name"] + " added " + correctStringForTypeOfRSVP(doc["expected_expression"]))
                        $(template_sub).find(".special-container").attr("href", window.location.origin + "/i/" + doc["greeting_id"])
                    } else {
                        $(template_sub).find(".special-container").addClass("d-none");
                    }

                    rsvp_html = rsvp_html + template_sub.html();
                })

                $(template).find(".rsvp_expression_widget_container").html(rsvp_html)
                $(template).attr("data-expression-id", doc["id"])

                $('#expression_array_container').append(template);
                $('.rsvp_open_full_list_model_button').on('click', function () {
                    if (appData.user_logged_in) {
                        open_rsvp_full_list_modal()
                    } else {
                        show_onboarding_login("view_rsvp")
                    }
                })
                resolve()
            }))

        }

        if (doc["type"] == "gif") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_gif').html());
                $(template).attr("data-expression-id", doc["id"])
                $('#expression_array_container').append(template);
                if (appData.gf == undefined || appData.gf == "") {
                    appData.gf = new GiphyFetch('5SSExMH6nNjOifOO5Wbleiebt80pLQ5z')
                }
                return appData.gf.gif(doc["text"]).then((result) => {
                    return imageLoadStarted(result.data.images.original.url)
                }).then((result) => {
                    $(`[data-expression-id="${doc["id"]}"]`).find(".gif_container").attr("src", result)
                    return true
                }).then((result) => {
                    console.log("img loaded function reurned");
                    resolve()
                })
            }))
        }

        if (doc["type"] == "gift") {
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_gift_card').html());
                $(template).find(".img_gift").attr("src", doc["img_url"]);
                $(template).attr("data-expression-id", doc["id"])
                $(template).find(".title_gift_product").html(doc["product_name"])
                $(template).find(".gift_giver_name").html(appData.sender_name)
                $(template).find(".open_redeem_gift_button").attr("item-id", doc["this_gift_id"])
                $('#expression_array_container').append(template);

                $(template).find(".open_redeem_gift_button").on('click', function (e) {
                    e.preventDefault()
                    tempData.gift_unique_id = $(this).attr("item-id")
                    if (appData.user_logged_in == false) {
                        tempData.thisContainer = this
                        show_onboarding_login("redeem_gift")
                    } else {
                        redeemGift(this)
                    }
                })
                resolve()
            }))
        }

        expression_counter = expression_counter + 1

        if (expression_counter == appData.expression_array.length && !appData.can_this_user_modify_greeting && !appData.is_type_invite) {
            // Last item in the list should be the account upsell
            promiseChain.push(new Promise((resolve, reject) => {
                var template = $($('#expression_array_save_account').html());
                $(template).find(".save_to_account_recipient").html(appData.sender_name)

                // define a JQuery function to show or hide elements
                $.fn.toggleClassBasedOnCondition = function (className, condition) {
                    return this.each(function () {
                        var $this = $(this);
                        $this.toggleClass(className, condition);
                    });
                };

                // checks if user is logged in and decides to show or hide the 'save_greeting_expression_feed_image'
                $(template).find(".save_greeting_expression_feed_image").toggleClassBasedOnCondition("d-none", !appData.user_logged_in);

                // Checks if greeting is saved and applies class accordingly
                if (appData.is_saved_greeting) {
                    $(template).find(".save_greeting_to_account").addClass("d-none");
                    $(template).find(".remove_greeting_to_account").removeClass("d-none");
                    $(template).find(".save_greeting_to_account_title").html(appData.sender_name + "'s greeting is securely saved in your account and it will never expire.");
                } else {
                    // If greeting is not saved
                    $(template).find(".save_greeting_to_account").removeClass("d-none");
                    $(template).find(".remove_greeting_to_account").addClass("d-none");

                    // Only if user is logged in, add suggestion to save greeting
                    if (appData.user_logged_in) {
                        $(template).find(".save_greeting_to_account_title").html("Make sure " + appData.sender_name + "'s greeting stays with you. Save it now and see it in your account.");
                    }
                }

                // Append template to '#expression_array_container'
                $('#expression_array_container').append(template);

                // If greeting is not saved and user is not logged in, then start a countdown and display tooltips and timer
                if (!appData.is_saved_greeting && !appData.user_logged_in) {
                    perpetualCountdown("2023-07-01T12:00:00");
                    $('[data-bs-toggle="tooltip"]').tooltip();
                    $(".count-down-timer-expression-container, .countdown_to_expire_timer").removeClass("d-none");
                }

                addSaveListeners()
                resolve()
            }))

        }
    })


    if (appData.can_this_user_modify_greeting) {
        if ((appData.expression_array.length == 0) || (appData.is_type_invite && appData.expression_array.length == 1)) {
            var usedSuggestions = [];

            if (appData.is_type_invite) {
                appData.occasion = "invite"
            }

            for (var i = 0; i < 3; i++) {
                var randomSuggestion;
                do {
                    randomSuggestion = appData.suggestions_array[appData.occasion][Math.floor(Math.random() * appData.suggestions_array[appData.occasion].length)];
                } while (usedSuggestions.includes(randomSuggestion));

                usedSuggestions.push(randomSuggestion);

                var template = $($('#expression_array_add_suggestions').html());
                $(template).find(".suggestion_expression_img").attr("src", window.location.protocol + "//" + window.location.host + "/assets/img/" + randomSuggestion.icon + ".png");
                $(template).find(".suggestion_expression_title").html(randomSuggestion.title)
                $('#expression_array_container').prepend(template);

                $(template).on('click', (function (suggestion) {
                    return function (e) {
                        e.preventDefault()
                        // use icon to navigate directly into experience
                        switch (suggestion.icon) {
                            case "video_expression":
                                onAddVideoClick();
                                break;
                            case "photo_expression":
                                onAddPhotoClick();
                                break;
                            case "ai_poem_expression":
                                onAddAiPoemClick();
                                break;
                            case "note_expression":
                                onAddNoteClick();
                                break;
                            case "gift_expression":
                                onAddGiftCardClick();
                                break;
                            case "gif_expression":
                                onAddGifClick();
                                break;
                            case "location_expression":
                                onAddLocationClick();
                                break;
                            default:
                                show_edit_modal()
                        }
                    }
                })(randomSuggestion))
            }
        }
    }




    $(".open-greetie-modal").on('click', function (e) {
        e.preventDefault()

        $("#play-greetie-modal").modal("show");

        const $button = $(this);
        const $parent = $button.closest('.expression_array_object_parent');
        const expressionId = $parent.data('expression-id');

        var videoUrl = read_expressionArray(expressionId, "files")[0]
        $("#watch-greetie-video").attr("src", videoUrl + "#t=0.5")
        var videoElement = $("#watch-greetie-video")[0]

        videoElement.play();
        const browser = bowser.getParser(window.navigator.userAgent);

        if (browser.getPlatform().type == "mobile") {
            $("#play-greetie-modal").modal("show");
            if (videoElement.requestFullscreen) {
                videoElement.requestFullscreen();
            } else if (videoElement.mozRequestFullScreen) { // Firefox
                videoElement.mozRequestFullScreen();
            } else if (videoElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                videoElement.webkitRequestFullscreen();
            } else if (videoElement.msRequestFullscreen) { // IE/Edge
                videoElement.msRequestFullscreen();
            }
        } else {
            $("#play-greetie-modal").modal("show");
        }
    })

    // Add listners to all share buttons
    $(".share-greetie-button").each(function () {
        $(this).on("click", function () {
            if (!isShareAPISupported()) {
                $(this).popover({
                    placement: 'bottom',
                    delay: {
                        "show": 500,
                        "hide": 100
                    }
                });
                setTimeout(function () {
                    $('.popover').fadeOut('slow');
                }, 3000);
            } else {
                $(this).popover("disable")

                const shareData = {
                    text: 'Open your Clipjoy',
                    url: "https://cljy.us/i/" + appData.extracted_url_id
                }
                navigator.share(shareData);
            }
        })
    })

    Promise.all(promiseChain).then((results) => {

        show_latest_greetie()
        drawModifySettingsModal()

        $('.image_could_be_processing').on('error', function () {
            $(this).parent().find('.video_processing_alert_expression_array').removeClass("d-none")
            $(this).parent().find('.video_play_button_container_expression_array').addClass("d-none")
            $(this).parent().find('.background_image_processing').removeClass("d-none")
        });

        $(".login-for-access-expression-wdigets-button").on('click', function (e) {
            e.preventDefault()
            show_onboarding_login("see_invite_details")
        })

        if (appData.is_type_invite) {
            if (appData.user_logged_in) {
                $(".login-for-access-expression-container").addClass("d-none")
                $(".note_content_preview_hidden").removeClass("note_content_preview_hidden")
            }
            if (appData.can_this_user_modify_greeting) {
                show_share_invite_bottom_bar()
                $('#sms_blast_open_modal_button').removeClass("d-none")
            } else {
                if (!appData.is_user_rsvpd) {
                    // User has RSVP'd
                    show_rsvp_invite_bottom_bar()
                } else {
                    show_rsvp_complete_invite_bottom_bar()
                    var text;
                    if (appData.user_rsvp_status == "yes") {
                        text = "You're going 👍"
                    }
                    if (appData.user_rsvp_status == "maybe") {
                        text = "You're a maybe 🤔"
                    }
                    if (appData.user_rsvp_status == "no") {
                        text = "You're not going 😢"
                    }
                    $('#rsvp_complete_event_change_rsvp_bottom_bar_button').html(text)

                    if (appData.user_rsvp_greeting_id != "" && appData.user_rsvp_greeting_id != undefined) {
                        $(".invite-greeting-rsvp-reply-options-container").addClass("d-none")
                        $(".invite-greeting-rsvp-reply-saved-container").removeClass("d-none")
                        $("#invite-greeting-rsvp-reply-link").attr("href", window.location.origin + "/i/" + appData.user_rsvp_greeting_id)
                    } else {
                        $(".invite-greeting-rsvp-reply-options-container").removeClass("d-none")
                        $(".invite-greeting-rsvp-reply-saved-container").addClass("d-none")
                    }
                }
            }
        } else if (appData.can_this_user_modify_greeting && appData.is_deletable_code && !appData.is_reply_greeting) {
            show_share_greeting_bottom_bar();
        } else if (appData.is_reply_greeting) {
            show_back_greeting_bottom_bar();
        }

        if (!appData.is_type_invite) {
            $(".login-for-access-expression-container").addClass("d-none")
            $(".note_content_preview_hidden").removeClass("note_content_preview_hidden")
        }

        removeLoadingIndicatorShade()

        $("#watch-greetie-title-container").css('transform', `scale(0.9)`);
        $("#watch-greetie-title-container").css('opacity', `0`);
        $("#expression_array_container").css('transform', 'translateY(-50px)');
        $("#expression_array_container").css('opacity', `0`);

        anime({
            targets: $("#watch-greetie-title-container")[0],
            opacity: 1,
            scale: 1.0,
            duration: 400,
            delay: 625,
            easing: 'easeOutElastic',
            complete: () => {
                anime({
                    targets: $("#expression_array_container")[0],
                    translateY: [50, 0],
                    opacity: [0, 1],
                    duration: 750,
                    easing: 'easeOutElastic',
                });
            },
        });

        return

    }).then((results) => {
        console.log("This greeting was successfully drawn");

        show_loading_indicator_on_primary_bottom_bar(false, "Next")
        show_loading_indicator_on_secondary_bottom_bar(false, "Next")

    })
        .catch((error) => {
            console.error("An error occurred while waiting for Promises:", error);
        });
}

$('.invite_to_event_bottom_bar_button').on('click', function (e) {
    e.preventDefault()
    $("#share_link_modal").modal("show")
})




// Setting up a threshold for how close to the bottom of the page the user needs to scroll to trigger the function.
// In this case, it's 100 pixels. Adjust this to suit your needs.
// var threshold = 150;
// var did_save_greeting_dialog_fire = false

// window.onscroll = function () {
//     var scrollPosition = window.scrollY || document.documentElement.scrollTop;
//     var windowSize = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
//     var bodyHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
//     var remaining = bodyHeight - windowSize - scrollPosition;

//     if (remaining < threshold) {
//         console.log("User is near the bottom of the page.");
//         if (!did_save_greeting_dialog_fire && !appData.is_saved_greeting && !appData.is_type_invite && !appData.user_logged_in) {
//             did_save_greeting_dialog_fire = true
//             show_onboarding_login("nudge_save_greeting_dialog")
//         }
//     }
// };



$('#invalid_code_modal_create_new_greeting').on('click', function (e) {
    e.preventDefault()
    var baseURL = window.location.origin;
    var newURL = baseURL + '/';
    window.open(newURL, '_blank');
})

window.write_expressionArray = function (id, key, value) {
    appData.expression_array.forEach(doc => {
        if (doc["id"] == id) {
            doc[key] = value
        }
    })
}

document.addEventListener('fullscreenchange', stopPlayingVideo);
document.addEventListener('webkitfullscreenchange', stopPlayingVideo);
document.addEventListener('mozfullscreenchange', stopPlayingVideo);
document.addEventListener('MSFullscreenChange', stopPlayingVideo);

function stopPlayingVideo() {
    const isFullscreen = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
    if (!isFullscreen) {
        var videoElement = $("#watch-greetie-video")[0]
        videoElement.pause()
        $("#play-greetie-modal").modal("hide");
    }
}

$("#greeting_settings_button").on('click', function (e) {
    e.preventDefault()
    $("#greeting_settings_modal").modal("show")
})

function drawModifySettingsModal() {
    if (appData.is_type_invite) {

        $("#settings_modal_greeting_options_container").addClass("d-none")
        $("#settings_modal_invite_options_container").removeClass("d-none")

        // Name of new event
        $("#modify_event_name_field").val(appData.invite_name)

        // Turn the switch on or off based
        $("#modify_event_name_time_tbd_switch").prop("checked", !appData.has_date_time_set);

        // Clear the options of the select element
        $('#modify_event_time_field').empty();

        // Build the options selector
        var hours, minutes, ampm;
        for (var i = 0; i <= 1440; i += 15) { // Extend to 1440 to include 24:00
            hours = Math.floor(i / 60) % 24; // Modulo 24 to convert 24 to 0
            minutes = i % 60;
            if (minutes < 10) {
                minutes = '0' + minutes; // adding leading zero
            }
            ampm = hours < 12 ? 'AM' : 'PM';

            // ie. value = 22:30, text = "10:30 PM"
            var optionValue = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
            var displayHours = hours % 12;
            if (displayHours === 0) {
                displayHours = 12;
            }
            var optionText = displayHours + ':' + minutes + ' ' + ampm;
            $('#modify_event_time_field').append($('<option>', {
                value: optionValue,
                text: optionText
            }));
        }

        if (appData.has_date_time_set) {

            var dateStr = appData.invite_date_time.toISOString().slice(0, 10); // Format date as YYYY-MM-DD
            var timeStr = appData.invite_date_time.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hour12: false }); // Format time as HH:MM (24HR)
            $('#modify_event_date_field').val(dateStr);
            $('#modify_event_time_field').val(timeStr);

            $(".modify_invite_time_options_container").removeClass("d-none")

        } else {

            $(".modify_invite_time_options_container").addClass("d-none")

            // Get the date and time fields
            const dateField = document.getElementById('modify_event_date_field');
            const timeField = document.getElementById('modify_event_time_field');

            // Get the current date and time
            const now = new Date();

            // Set the value of the date field to the current date
            dateField.value = now.toISOString().slice(0, 10);

            // Set the value of the time field to the current time (without seconds)
            var currentHour = now.getHours();
            var currentMinutes = now.getMinutes();
            var nearest15Minutes = Math.ceil(currentMinutes / 15) * 15;
            if (nearest15Minutes === 60) {
                currentHour += 1;
                nearest15Minutes = 0;
            }
            // If the currentHour rounds up to 24, set it to 0
            if (currentHour === 24) {
                currentHour = 0;
            }
            timeField.value = `${currentHour.toString().padStart(2, '0')}:${nearest15Minutes.toString().padStart(2, '0')}`;
        }


    } else {

        $("#settings_modal_greeting_options_container").removeClass("d-none")
        $("#settings_modal_invite_options_container").addClass("d-none")

        // Selected
        $('#modify_occasion_choose_prompt_dropdown').val(appData.occasion);

        // Add recips name
        $("#modify_recipients_name_personalize_card").val(appData.recipient_name)

    }
}

$('#modify_event_name_time_tbd_switch').change(function () {
    if ($(this).is(":checked")) {
        $(".modify_invite_time_options_container").addClass("d-none")
    } else {
        $(".modify_invite_time_options_container").removeClass("d-none")
    }
});



// Bind a click event handler to the 'Save changes' button
$('#modify_greeting_modal_save_changes_button').on('click', function (e) {
    // Prevent the default click action
    e.preventDefault()

    // Boolean to decide if the form should be submitted
    var shouldSubmitForm = true

    // Validation: if 'is_type_invite' is true and event name field is empty, mark it invalid and don't submit the form
    if (appData.is_type_invite && $("#modify_event_name_field").val() == "") {
        $("#modify_event_name_field").addClass("is-invalid")
        shouldSubmitForm = false
    }

    // Validation: if 'is_type_invite' is false and recipient name field is empty, mark it invalid and don't submit the form
    if (!appData.is_type_invite && $("#modify_recipients_name_personalize_card").val() == "") {
        $("#modify_recipients_name_personalize_card").addClass("is-invalid")
        shouldSubmitForm = false
    }

    // If all validations passed
    if (shouldSubmitForm) {
        // Remove invalid class from fields
        $("#modify_recipients_name_personalize_card").removeClass("is-invalid")
        $("#modify_event_name_field").removeClass("is-invalid")

        // Temporarily disable the button and show loading spinner
        $(this).prop('disabled', true).html(
            `<div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>`);

        // Check if the event has a specific time set
        var event_has_time = !($('#modify_event_name_time_tbd_switch').is(':checked'))
        var inputDateTime = undefined

        // If event has time and is of type invite, parse date and time input to Date object
        if (event_has_time && appData.is_type_invite) {
            const dateInput = $("#modify_event_date_field").val()
            const timeInput = $("#modify_event_time_field").val()
            inputDateTime = new Date(dateInput + 'T' + timeInput);
        }

        // Create callable firebase function 'post_modifyGreetingSettings'
        var post_modifyGreetingSettingsFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_modifyGreetingSettings');
        post_modifyGreetingSettingsFunction({
            docId: appData.extracted_url_id,
            invite_name: $("#modify_event_name_field").val(),
            has_date_time_set: event_has_time,
            invite_date_time: inputDateTime,
            recipient_name: $("#modify_recipients_name_personalize_card").val(),
            user_selected_occasion: $("#modify_occasion_choose_prompt_dropdown").val()
        }).then((result) => {
            // Update appData with new values
            appData.has_date_time_set = event_has_time
            appData.invite_date_time = inputDateTime
            appData.recipient_name = $("#modify_recipients_name_personalize_card").val()
            appData.invite_name = $("#modify_event_name_field").val()
            appData.occasion = $("#modify_occasion_choose_prompt_dropdown").val()

            // Draw the Greetie
            drawGreetie()

            // Close the modal
            $("#greeting_settings_modal").modal("hide");

            // Reset the button
            $('#modify_greeting_modal_save_changes_button').prop('disabled', false).html("Save changes")
        }).catch((e) => {
            console.log(e);
            // In case of an error, reset the button and display the error message
            $('#modify_greeting_modal_save_changes_button').prop('disabled', false).html("Save changes")
            alert(e.message)
        })
    }
})



window.read_expressionArray = function (id, key) {
    var result;
    appData.expression_array.forEach(doc => {
        if (doc["id"] == id) {
            result = doc[key]
        }
    })
    return result
}

function removeLoadingIndicatorShade() {
    anime({
        targets: $("#loading_code_details")[0],
        translateY: '-100%',
        easing: 'easeInOutQuart',
        duration: 600,
        complete: function () {
            // Hide the modal after the animation is completed
            $('#loading_code_details').modal('hide');
        }
    });
}

window.timeAgo = function (date) {
    const currentTime = new Date();
    const seconds = Math.floor((currentTime - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days === 1 ? '' : 's'} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours === 1 ? '' : 's'} ago`;
    } else if (minutes > 0) {
        return `${minutes} min${minutes === 1 ? '' : 's'} ago`;
    } else if (seconds > 0) {
        return `${seconds} sec${seconds === 1 ? '' : 's'} ago`;
    } else {
        return "just now"
    }
}

window.returnRsvp = function (text) {
    if (text == "no") {
        return "not going 😢"
    }
    if (text == "yes") {
        return "going 👍"
    }
    if (text == "maybe") {
        return "maybe 🤔"
    }
}

window.redeemGift = function (button) {
    var gift_is_activated = read_expressionArray(tempData.gift_unique_id, "gift_activated")
    var gift_product_name = read_expressionArray(tempData.gift_unique_id, "product_name")
    var gift_is_gift_card = read_expressionArray(tempData.gift_unique_id, "is_gift_card")

    if (gift_is_activated == true) {

        // Save the reference to the button for further use
        var $button = $(button);

        // Save the original button text to restore it later
        var originalButtonText = $button.html();

        // Disable the button and show a loading spinner
        $button.prop('disabled', true).html(
            `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>`
        );

        // Firebase function to get the gift card code
        var get_showGiftCardCodeFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_showGiftCardCode');

        get_showGiftCardCodeFunction({
            docId: appData.extracted_url_id,
            uniqueDocId: tempData.gift_unique_id
        }).then((result) => {
            // If the operation is successful
            var returnData = result.data
            // Show the gift details modal
            show_gift_details_modal(returnData["gift_img"], returnData["is_gift_card"], returnData["card_details"], returnData["gift_title"], createAmazonUrl(returnData["gift_amazon_id"]))
            // Show the redeem code modal
            $("#watch_gift_activated_redeem_code").modal("show");
            // Restore the original button text and re-enable the button
            $button.html(originalButtonText).prop('disabled', false);
        }).catch((e) => {
            // If there's an error
            // Log the error in the console, restore the original button text, re-enable the button and show an alert
            console.log(e);
            $button.html(originalButtonText).prop('disabled', false);
            alert(e.message)
        })

    } else {
        show_redeem_gift_view(gift_product_name, gift_is_gift_card)
    }

}



function imageLoadStarted(url) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const img = new Image();

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onload = () => {
            if (xhr.status === 200) {
                img.src = URL.createObjectURL(xhr.response);
            } else {
                reject('Error loading image');
            }
        };

        xhr.onerror = () => {
            reject('Error loading image');
        };

        xhr.onprogress = () => {
            resolve(url);
        };

        xhr.send();
    });
}




function loadImage(img) {
    return new Promise((resolve, reject) => {
        if (img.complete) {
            resolve(img);
        } else {
            img.onload = () => {
                resolve(img);
            };
            img.onerror = (error) => {
                reject(error);
            };
        }
    });
}




function addSaveListeners() {
    $('.save_greeting_to_account').on('click', function (e) {
        e.preventDefault();
        if (appData.user_logged_in == true) {
            $(".save_greeting_to_account").html(
                `<div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Saving...</span>
                </div>`
            );
            toggle_save_greeting_account(false)
            if (appData.can_this_user_modify_greeting == false && appData.has_recipient_claimed == false) {
                $("#claim_greeting_modal").modal("show");
            }
        } else {
            show_onboarding_login("save_greeting_to_account")
        }
    })
    $('.remove_greeting_to_account').on('click', function (e) {
        e.preventDefault();
        $(".remove_greeting_to_account").html(
            `<div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Removing...</span>
            </div>`
        );
        toggle_save_greeting_account(true)
    })
}


window.show_gift_details_modal = function (gift_img, is_gift_card, card_code_details, gift_title, gift_url) {
    $(".redeem_gift_card_number_modal").val(card_code_details)
    $(".redeem_photo_of_gift_modal").attr("src", gift_img)
    $(".redeem_title_of_gift_modal").html(gift_title)

    if (is_gift_card) {
        $(".redeem_gift_physical_instuctions_modal_container").addClass("d-none")
        $(".redeem_gift_card_code_instuctions_modal_container").removeClass("d-none")
    } else {
        $(".redeem_gift_open_url_button_modal").attr("href", gift_url)
        $(".redeem_gift_physical_instuctions_modal_container").removeClass("d-none")
        $(".redeem_gift_card_code_instuctions_modal_container").addClass("d-none")
    }

    $("#watch_gift_activated_redeem_code").modal("show");
}

window.isShareAPISupported = function () {
    var isShareApiSupported = navigator.share != undefined;
    if (!isShareApiSupported) {
        return false;
    }
    return true
}

window.show_redeem_gift_view = function (currentGiftName, is_gift_card) {
    if (appData.watch_gift_is_activated == true) {
        $("#watch_gift_activated_redeem_code").modal("show");
    } else {
        $("#watch_gift_activate_modal").modal("show");
        if (is_gift_card) {
            $(".swap_gift_value_container").addClass("d-none")
        } else {
            $(".swap_gift_value_container").removeClass("d-none")
        }
    }
}



/**
 * Event handler for the 'redeem_gift_card_modal_button' click event.
 * It calls a Firebase function to redeem a user gift based on an id and a unique id.
 * It also checks a checkbox to decide if the gift should be swapped to an Amazon card.
 * If the operation is successful, a modal with the gift details is shown and another modal is hidden.
 * In case of an error, an error message is shown as an alert.
 * During the operation, the button is disabled to prevent repeated clicks.
 */
$('#redeem_gift_card_modal_button').on('click', function (e) {
    // Prevent the default action of the click event
    e.preventDefault()

    // Save the reference to the button for further use
    var $button = $(this);

    // Save the original button text to restore it later
    var originalButtonText = $button.html();

    // Disable the button and show a loading spinner
    $button.prop('disabled', true).html(
        `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>`
    );

    // Firebase function to redeem the user gift
    var activateGiftFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_redeemUserGift');

    activateGiftFunction({
        docId: appData.extracted_url_id,
        uniqueDocId: tempData.gift_unique_id,
        swapToAmazonCard: $('#swap_gift_value_dropdown').is(':checked')
    }).then((result) => {
        // If the operation is successful
        var returnData = result.data
        // Show the gift details modal
        show_gift_details_modal(returnData["gift_img"], returnData["is_gift_card"], returnData["card_details"], returnData["gift_title"], createAmazonUrl(returnData["gift_amazon_id"]))
        // Update the expression array
        write_expressionArray(tempData.gift_unique_id, "gift_activated", true)
        // Hide the activate modal
        $("#watch_gift_activate_modal").modal("hide");
        // Restore the original button text and re-enable the button
        $button.html(originalButtonText).prop('disabled', false);
    }).catch((e) => {
        // If there's an error
        // Restore the original button text, re-enable the button and show an alert
        $button.html(originalButtonText).prop('disabled', false);
        alert(e.message)
    })
})



function createAmazonUrl(amazonProductId) {
    return "https://www.amazon.com/dp/" + amazonProductId + "/?tag=clipjoy-20"
}

var temp_currentDiv
$("#reorder_delete_button").on('click', function (e) {
    e.preventDefault()

    if ((appData.expression_array.length == 0) || (appData.is_type_invite && appData.expression_array.length == 1)) {
        alert("You don't have anything to reorder or delete. Try adding something from the suggestions first.")
    } else {
        $(".expression_array_main_card_action_hide_able").each(function () {
            $(this).addClass("d-none")
        });

        $(".expression_array_main_card_action_reorder_delete_button").each(function () {
            $(this).removeClass("d-none")
        });

        $(".greeting_home_expression_card").each(function () {
            $(this).removeClass("mb-5")
            $(this).addClass("mb-8")
        });

        scroll.animateScroll(0);

        showButtonBar(true, false)
        $("#bottom_action_bar_button_primary").removeClass("d-none")
        $("#bottom_action_bar_button_secondary").html("Back")
        $("#bottom_action_bar_button_primary").html("Save")

        $(".expression_array_object_parent .move_expression_up_button").on("click", function (e) {
            e.preventDefault()
            var $currentButton = $(this);
            var $currentDiv = $currentButton.closest(".expression_array_object_parent");
            var $previousDiv = $currentDiv.prev(".expression_array_object_parent");
            var $nextDiv = $currentDiv.next(".expression_array_object_parent");

            // If the previous div exists, move the current div up (before the previous div)
            if ($previousDiv.length > 0) {
                $currentDiv.insertBefore($previousDiv);
            }
            // If the next div exists, move the current div down (after the next div)
            else if ($nextDiv.length > 0) {
                $currentDiv.insertAfter($nextDiv);
            }
        });

        $(".expression_array_object_parent .move_expression_down_button").on("click", function (e) {
            e.preventDefault();
            var $currentButton = $(this);
            var $currentDiv = $currentButton.closest(".expression_array_object_parent");
            var $nextDiv = $currentDiv.next(".expression_array_object_parent");

            // If the next div exists, move the current div down (after the next div)
            if ($nextDiv.length > 0) {
                $currentDiv.insertAfter($nextDiv);
            }
        });


        $(".expression_array_object_parent .remove_expression_button").on("click", function (e) {
            e.preventDefault()
            var $currentButton = $(this);
            temp_currentDiv = $currentButton.closest(".expression_array_object_parent");
            $("#remove_individual_video_modal").modal("show")
        })
    }

})



var selectedBlastTypes = [];

$("#send_blast_sms_button").on("click", function (e) {
    e.preventDefault()

    $("#send_blast_sms_button").html(
        `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div> `);

    var post_inviteTextBlastFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_inviteTextBlast');
    post_inviteTextBlastFunction({
        message: $("#sms_blast_text_box").val(),
        targetted_rsvps: selectedBlastTypes,
        docId: appData.extracted_url_id
    }).then((result) => {
        // Do nothing. Saved worked
        alert("Your SMS message was sent! It might take a few seconds for everyone to receive it.")
        $("#sms_blast_modal").modal("hide")
        $("#send_blast_sms_button").html(`Send SMS`);
    }).catch((e) => {
        alert(e.message)
        $("#send_blast_sms_button").html(`Send SMS`);
    })
})


$('.text-blast-user-category-option').on('click', function () {
    const $button = $(this);
    const blastType = $button.data('text-blast-type');
    $button.toggleClass('special-secondary-btn');
    if ($button.hasClass('special-secondary-btn')) {
        selectedBlastTypes = selectedBlastTypes.filter(item => item !== blastType);
    } else {
        selectedBlastTypes.push(blastType);
    }
});


$('#sms_blast_open_modal_button').on('click', function (e) {
    e.preventDefault()
    $("#sms_blast_modal").modal("show")
    selectedBlastTypes = []
    selectedBlastTypes.push($('.text-blast-user-category-option:first').data('text-blast-type'));
})


$("#delete_this_individual_button").on("click", function (e) {
    e.preventDefault()
    temp_currentDiv.remove()
    $("#remove_individual_video_modal").modal("hide")

    var post_removeSingleExpressionFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_removeSingleExpression');
    post_removeSingleExpressionFunction({
        docId: appData.extracted_url_id,
        expressionId: temp_currentDiv.attr("data-expression-id")
    }).then((result) => {
        // Do nothing. Saved worked
        console.log("do nothing, it looks good to me");
    }).catch((e) => {
        alert(e.message)
    })
})




window.hide_reorder_delete_greeting_expressions = function () {
    $(".expression_array_main_card_action_hide_able").each(function () {
        $(this).removeClass("d-none")
    });

    $(".expression_array_main_card_action_reorder_delete_button").each(function () {
        $(this).addClass("d-none")
    });

    $(".greeting_home_expression_card").each(function () {
        $(this).addClass("mb-5")
        $(this).removeClass("mb-8")
    });

    if (appData.can_this_user_modify_greeting && appData.is_deletable_code && !appData.is_reply_greeting) {
        show_share_greeting_bottom_bar();
    } else if (appData.is_reply_greeting) {
        show_back_greeting_bottom_bar();
    } else {
        resetBottomBarButtons()
    }
}


window.save_updated_expressions_array = function () {

    show_loading_indicator_on_primary_bottom_bar(true)
    const photoElements = document.querySelectorAll('.expression_array_object_parent');
    const newOrderArray = Array.from(photoElements).map(element => element.dataset.expressionId);

    var post_saveExpressionOrderFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_saveExpressionOrder');
    post_saveExpressionOrderFunction({
        docId: appData.extracted_url_id,
        order: newOrderArray
    }).then((result) => {
        // Do nothing. Saved worked
        hide_reorder_delete_greeting_expressions()
    }).catch((e) => {
        alert(e.message)
    })

}





let canvas;
let c;
const circleCount = window.innerWidth / 9;
const mouse = { x: null, y: null };
const maxRadius = window.innerWidth / 2;

const Color = {
    vector: ["#F4BCFD", "#BFF5F3"],
    getRandom: () => {
        return Color.vector[Math.floor(Math.random() * Color.vector.length)];
    }
};

class Circle {
    constructor(
        r_min = randomNumber(maxRadius * 0.9, 20),
        x = randomNumber(canvas.width, r_min),
        y = randomNumber(canvas.height, r_min),
        dx = randomNumber(1, -2, [0]),
        dy = randomNumber(1, -1, [0]),
        color = Color.getRandom()
    ) {
        this.r_min = r_min;
        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        this.color = color;
        this.r = r_min;

        this.draw();
    }

    side() {
        return {
            right: this.x + this.r,
            left: this.x - this.r,
            bottom: this.y + this.r,
            top: this.y - this.r
        };
    }

    draw() {
        c.beginPath();
        c.arc(this.x, this.y, this.r, 0, Math.PI * 2, false);
        c.fillStyle = this.color;
        c.fill();
    }

    run() {
        // detect collision
        if (this.side().right > canvas.width || this.side().left < 0) this.dx *= -1;
        if (this.side().bottom > canvas.height || this.side().top < 0)
            this.dy *= -1;

        // increase size
        if (
            (mouse.x != mouse.y) != 0 &&
            this.side().left - mouse.x < 50 &&
            mouse.x - this.side().right < 50 &&
            this.side().top - mouse.y < 50 &&
            mouse.y - this.side().bottom < 50 &&
            this.r < maxRadius
        )
            this.r += 3;
        else if (this.r > this.r_min) this.r -= 1;

        // change position
        this.x += this.dx;
        this.y += this.dy;

        this.draw();
    }
}


let circles = [];

function init_loading_bg() {
    canvas = $("#canvas")[0]
    c = canvas.getContext("2d");
    resetCanvas();
    animation();
    for (let i = circleCount; i > 0; i--) {
        circles.push(new Circle());
    }
}

function animation() {
    c.clearRect(0, 0, canvas.width, canvas.height);
    circles.forEach((circle) => circle.run());
    //   requestAnimationFrame(animation);
    setTimeout(() => {
        requestAnimationFrame(animation);
    }, 5);
}

function resetCanvas() {
    c.canvas.width = window.innerWidth;
    c.canvas.height = window.innerHeight;
}

function randomNumber(max, min, forbidden) {
    max = max || 1;
    min = min || 0;
    forbidden = forbidden || [];

    let res;

    do {
        res = Math.floor(min + Math.random() * (max - min));
    } while (forbidden.some(function (num) {
        return num == res;
    }));

    return res;
}



