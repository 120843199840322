window.onboarding_show_write_note = function () {
    appData.current_user_view = "write_note_edit"
    show_write_note()
    scroll.animateScroll(0);
}

var orignal_text = ""

window.show_write_note = function () {

    hide_allViews()
    $('#vibe_items_collection').html("")
    $("#choose_write_note").removeClass("d-none")

    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").addClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")

    scroll.animateScroll(0);

    if (appData.is_type_invite) {
        $(".spice_up_note_container").removeClass("d-none")
        $('.note_ai_suggestions_button_container').html("")
        $("#write_a_note_textarea").attr("rows",5)
        $(".choose_write_note_view_title").html("Add event details")

        var ai_prompt_suggestions = [
            ["More serious", "serious"],
            ["Make it 21+", "mature"],
            ["More funny", "funny"],
            ["Make it random", "random"],
            ["Shorten it", "shorten"],
            ["Great Gatsby vibes", "gatsby"],
            ["Make it rhyme", "rhyme"],
            ["Cringe mode", "cringe"],
            ["Just minor touchups", "touchups"],
            ["Make it epic", "epic"]
        ]

        ai_prompt_suggestions = shuffleArray(ai_prompt_suggestions)

        ai_prompt_suggestions.forEach(function (item) {
            var template = $($('#note_ai_suggestion_button_template').html());
            $(template).attr("data-note-ai-suggestion-type", item[1])
            $(template).find(".note-ai-suggestion-text").html(item[0])
            $('.note_ai_suggestions_button_container').append(template);
        });
    }

    $(".note-ai-suggestion-button").on('click', function () {
        const selectedAction = $(this).data('note-ai-suggestion-type');
        orignal_text = $("#write_a_note_textarea").val()
    
        // Show spinner and disable button
        const $thisButton = $(this);
        $thisButton.find('.spinner-border, .spinner-text').removeClass('d-none');
        $thisButton.find('.bi-magic, .note-ai-suggestion-text').addClass('d-none');
        $thisButton.prop('disabled', true);
    
        // Disable all other buttons
        $(".note-ai-suggestion-button").not($thisButton).prop('disabled', true);
    
        $("#bottom_action_bar_button_primary").addClass("d-none")
    
        var get_aiNoteFunction = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_aiNote');
        return get_aiNoteFunction({
            tag_id: appData.extracted_url_id,
            text: $("#write_a_note_textarea").val(),
            title: appData.invite_name,
            selection: selectedAction
        }).then(function (result) {
    
            // Enable all buttons
            $(".note-ai-suggestion-button").prop('disabled', false);
    
            $(".spice_up_note_container").addClass("d-none")
            appData.current_user_view = "write_note_review"
            $("#write_a_note_textarea").attr("rows",15)
            $("#write_a_note_textarea").val(result.data)
            $(".choose_write_note_view_title").html("Review what we made")
    
            $thisButton.find('.spinner-border, .spinner-text').addClass('d-none');
            $thisButton.find('.bi-magic, .note-ai-suggestion-text').removeClass('d-none');
    
            showBottomBarIfTextNotZero()
    
        }).catch((e) => {
            console.log(e);
            alert(e.message)
    
            // Enable all buttons
            $(".note-ai-suggestion-button").prop('disabled', false);
    
            $(".choose_write_note_view_title").html("Add event details")
            $thisButton.find('.spinner-border, .spinner-text').addClass('d-none');
            $thisButton.find('.bi-magic, .note-ai-suggestion-text').removeClass('d-none');
    
            showBottomBarIfTextNotZero()
        })
    });
    
}

function showBottomBarIfTextNotZero(){
    if ($('#write_a_note_textarea').val().length > 0) {
        $("#bottom_action_bar_button_primary").removeClass("d-none")
        $("#bottom_action_bar_button_primary").html("Save")
    } else {
        $("#bottom_action_bar_button_primary").addClass("d-none")
    }
}

$('#write_a_note_textarea').on('keyup', function () {
    showBottomBarIfTextNotZero()
});

window.resetNoteVeiwFromAIResult = function () {
    appData.current_user_view = "write_note_edit"
    $("#write_a_note_textarea").val(orignal_text)
    $("#write_a_note_textarea").attr("rows",5)
    $(".spice_up_note_container").removeClass("d-none")
    $(".choose_write_note_view_title").html("Add event details")
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}
