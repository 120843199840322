var expectedActionPostLogin = ""

window.show_onboarding_login = function (expectedAction) {
    $("#login_with_phone_number_modal").modal("show");
    expectedActionPostLogin = expectedAction

    

    if (expectedActionPostLogin == "nudge_save_greeting_dialog") {
        $(".card_expire_on_login_container").removeClass("d-none")
        $("#login_window_subtext").html("Save this to your account forever and become a Clipjoy+ member for free. No credit card required.")
        if (!appData.has_recipient_claimed) {
            $(".request_if_user_is_card_recipient_container").removeClass("d-none")
        }
        return
    }

    $(".card_expire_on_login_container").addClass("d-none")

    if (expectedActionPostLogin == "confirm_upload") {
        $("#login_window_subtext").html("We will link this to your phone number to safeguard your changes from any unauthorized modifications by others.")
        return
    }
    if (expectedActionPostLogin == "see_invite_details") {
        $("#login_window_subtext").html("Verify your phone number to see the invite details.")
        return
    }
    if (expectedActionPostLogin == "check_if_edit_access") {
        $("#login_window_subtext").html("We need to verify your ownership of this in order to make edits.")
        return
    }
    if (expectedActionPostLogin == "view_rsvp") {
        $("#login_window_subtext").html("Verify your number to see the guest list.")
        return
    }
    if (expectedActionPostLogin == "show_my_stuff") {
        $("#login_window_subtext").html("We need to know who you are before we show you account information and stuff.")
        return
    }
    if (expectedActionPostLogin == "edit_greetie") {
        $("#login_window_subtext").html("We need to confirm your ownership of this greeting to edit it.")
        return
    }
    if (expectedActionPostLogin == "redeem_gift") {
        $("#login_window_subtext").html("We need to confirm you are the correct recipient of this gift. Please provide your number below.")
        return
    }
    if (expectedActionPostLogin == "save_greeting_to_account") {
        $("#login_window_subtext").html("Save this to your account forever and become a Clipjoy+ member for free. No credit card required.")
        if (!appData.has_recipient_claimed) {
            $(".request_if_user_is_card_recipient_container").removeClass("d-none")
        }
        return
    }
}


// This function listens to changes in the login state
window.login_state_listener = function (expectedAction) {
    
    // Use the Firebase authentication state change listener
    firebaseUtils.onAuthStateChanged(firebaseUtils.auth, (user) => {
        
        // Save the current user into the appData object
        appData.user = firebaseUtils.auth.currentUser;
        
        // Check if the user is logged in
        if (appData.user) {

            // URL encode the user's display name and uid, as well as the extracted url id from the app data
            var name = encodeURIComponent(user.displayName);
            var user_id = encodeURIComponent(user.uid);
            var tag_id = encodeURIComponent(appData.extracted_url_id);

            // Add a feedback button with user's details embedded in it
            $(".feedback_button").html('<a data-tf-slider="YtWOyNq6" data-tf-position="right" data-tf-hidden="name=' + name + ',user_id=' + user_id + ',tag_id=' + tag_id + '" data-tf-opacity="100" data-tf-iframe-props="title=Clipjoy Feedback" data-tf-transitive-search-params data-tf-medium="snippet" class="px-1 px-md-3 feedback_button">Send us feedback</a>')

            // Show the auth state banner and user's phone number, also update the appData object with user's phone number and logged in state
            $(".current_user_auth_state_banner").removeClass("d-none");
            $(".user-phone-number-string").html(libphonenumber.parsePhoneNumber(user.phoneNumber).formatNational());
            $(".user-account-name-string").html(user.displayName)
            appData.user_phone_number = user.phoneNumber
            appData.user_logged_in = true
        } else {
            // If the user is not logged in, hide the auth state banner, clear the phone number and update the appData logged in state
            $(".current_user_auth_state_banner").addClass("d-none");
            $("#user-phone-number-string").html("")
            appData.user_logged_in = false

            // Create feedback button with only the tag_id
            var tag_id = encodeURIComponent(appData.extracted_url_id);
            $(".feedback_button").html('<a data-tf-slider="YtWOyNq6" data-tf-position="right" data-tf-hidden="tag_id=' + tag_id + '" data-tf-opacity="100" data-tf-iframe-props="title=Clipjoy Feedback" data-tf-transitive-search-params data-tf-medium="snippet" class="px-1 px-md-3 feedback_button">Send us feedback</a>')
        }

        // Append script to the head of the document to embed a typeform feedback form
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = "//embed.typeform.com/next/embed.js";
        document.getElementsByTagName('head')[0].appendChild(script);

    });

    // Add a click listener to the logout button to sign out the user when clicked
    $("#logout_user_modal_action").on("click", function () {
        firebaseUtils.signOut(firebaseUtils.auth).then(() => {
            window.location.reload();
        }).catch((error) => {
            // An error happened.
            console.error(error); // I've added a console error output here to help with debugging
        });
    })

    // Add a click listener to the logout user button to show the logout modal when clicked
    $("#logout_user").on("click", function () {
        $("#logout_modal").modal("show");
    })
}


window.show_onboarding_login_otp_code = function () {
    $("#login_with_phone_number_verify_modal").modal("show");
}

$("#login_modal_send_sms_code_button").on("click", function () {
    $("#login_modal_send_sms_code_button").html(
        `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>`
    );
    var formIsValid = true
    if ($("#login_phone_number_field").val() == "" || $("#login_phone_number_field").val() == undefined) {
        formIsValid = false
    }

    var phoneNumber = "+1" + $("#login_phone_number_field").val()
    const appVerifier = window.recaptchaVerifier;

    if (libphonenumber.isValidPhoneNumber(phoneNumber) && formIsValid) {
        if (["MOBILE", "FIXED_LINE_OR_MOBILE", "PERSONAL_NUMBER"].includes(libphonenumber.parsePhoneNumber(phoneNumber).getType())) {

            return firebaseUtils.signInWithPhoneNumber(firebaseUtils.auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;

                    var shouldUserProvideAdditionalDetails_function = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_shouldUserProvideAdditionalDetails');
                    return shouldUserProvideAdditionalDetails_function({
                        phoneNumber: phoneNumber
                    })

                }).then(function (result) {

                    if (result.data) {
                        $(".additional_details_needed_view").removeClass("d-none")
                        appData.user_more_detailed_needed = true
                    } else {
                        $(".additional_details_needed_view").addClass("d-none")
                    }

                    $("#login_with_phone_number_modal").modal("hide");
                    $("#login_with_phone_number_verify_modal").modal("show");

                }).catch((error) => {
                    console.log(error);
                    alert("Something went wrong when trying to verify your phone number. Try refreshing the page and don't use a Private Tab.")
                });

        } else {
            $("#login_phone_number_field").addClass("is-invalid");
            $("#login_modal_send_sms_code_button").html("Login & Verify Number")
            formIsValid = false
        }
    } else {
        $("#login_phone_number_field").addClass("is-invalid");
        $("#login_modal_send_sms_code_button").html("Login & Verify Number")
        formIsValid = false
    }
})



// Add a speed bump

var login_verify_number_attempts = 0

$("#login_modal_verify_otc_button").on("click", function () {
    $("#login_modal_verify_otc_button").html(
        `<div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>`
    );
    var isInvalid = false

    $("#login_sms_otc_code").removeClass("is-invalid");
    $("#login_user_given_name").removeClass("is-invalid");
    $("#login_user_birthday_day").removeClass("is-invalid");
    $("#login_user_birthday_month").removeClass("is-invalid");

    if ($("#login_sms_otc_code").val() == "" || $("#login_sms_otc_code").val() == undefined) {
        $("#login_sms_otc_code").addClass("is-invalid");
        $("#login_modal_verify_otc_button").html("Verify SMS Code");
        isInvalid = true
    }

    if (appData.user_more_detailed_needed) {
        if ($("#login_user_given_name").val() == "" || $("#login_user_given_name").val() == undefined) {
            $("#login_user_given_name").addClass("is-invalid");
            $("#login_modal_verify_otc_button").html("Verify SMS Code");
            isInvalid = true
        }
        if ($("#login_user_birthday_day").val() == "0" || parseInt($("#login_user_birthday_day").val()) > 31 && parseInt($("#login_user_birthday_day").val()) < 0) {
            $("#login_user_birthday_day").addClass("is-invalid");
            $("#login_modal_verify_otc_button").html("Verify SMS Code");
            isInvalid = true
        }
        if ($("#login_user_birthday_month").val() == "0" || parseInt($("#login_user_birthday_month").val()) > 12 && parseInt($("#login_user_birthday_month").val()) < 0) {
            $("#login_user_birthday_month").addClass("is-invalid");
            $("#login_modal_verify_otc_button").html("Verify SMS Code");
            isInvalid = true
        }
        if (!$('#allow_terms_checkbox').is(':checked')) {
            $('.allow_terms_checkbox_feedback').addClass("d-block")
            $("#login_modal_verify_otc_button").html("Verify SMS Code");
            isInvalid = true
        }else{
            $('.allow_terms_checkbox_feedback').removeClass("d-block")
        }
        
    }

    login_verify_number_attempts = login_verify_number_attempts + 1

    if (!isInvalid) {
        window.confirmationResult.confirm($("#login_sms_otc_code").val()).then((result) => {

            if (appData.user_more_detailed_needed) {
                $(".user-account-name-string").html($("#login_user_given_name").val())

                //request_if_user_is_card_recipient
                var pushAdditionalDetails_function = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_writeUserDetails');
                return pushAdditionalDetails_function({
                    birthday_day: parseInt($("#login_user_birthday_day").val()),
                    birthday_month: parseInt($("#login_user_birthday_month").val()),
                    first_name: $("#login_user_given_name").val(),
                    sms_marketing_permission: $('#enable_marketing_user').is(':checked')
                }).then(() => {
                    login_verify_number_attempts = 0
                    // Sync birthdays if the user has never created an account
                    if (expectedActionPostLogin == "save_greeting_to_account" && !appData.has_recipient_claimed && $('#request_if_user_is_card_recipient').is(':checked')) {
                        claimCardRecipient()
                    }
                    return
                })

            }
            return true

        }).then((result) => {

            if (expectedActionPostLogin == "generate_new_greetie_code") {
                check_personalize_form_complete()
                return
            }

            // Everything below this can complete after hiding the modal
            $("#login_with_phone_number_verify_modal").modal("hide");
            $("#login_modal_verify_otc_button").html("Verify SMS Code");

            if (expectedActionPostLogin == "save_greeting_to_account") {
                $(".save_greeting_to_account").html(
                    `<div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Removing...</span>
                    </div>`
                );
                toggle_save_greeting_account(false)
                return
            }
            if (expectedActionPostLogin == "confirm_upload") {
                show_onboarding_uploading_shopping()
                return
            }
            if (expectedActionPostLogin == "choose_music" || expectedActionPostLogin == "choose_music_skip") {
                $(".bottom_action_bar_small_skip_music_link").addClass("d-none")
                show_onboarding_uploading_shopping()
                return
            }
            if (expectedActionPostLogin == "watch_greetie") {
                show_edit_modal()
                return
            }
            if (expectedActionPostLogin == "view_rsvp") {
                open_rsvp_full_list_modal()
                return
            }
            if (expectedActionPostLogin == "choose_ai_writing") {
                fetch_ai_writing_poem()
                return
            }
            if (expectedActionPostLogin == "redeem_gift") {
                redeemGift(tempData.thisContainer)
                return
            }
            if (expectedActionPostLogin == "rsvp_change") {
                change_rsvp(tempData.thisContainer)
                return
            }
            if (expectedActionPostLogin == "write_note") {
                show_onboarding_uploading_shopping()
                return
            }
            if (expectedActionPostLogin == "choose_gif") {
                show_onboarding_uploading_shopping()
                return
            }
            if (expectedActionPostLogin == "show_my_stuff") {
                show_stuff("favs")
                return
            }
            if (expectedActionPostLogin == "check_if_edit_access") {
                show_loading_indicator_on_primary_bottom_bar(true, "Next")
                pull_recent_greetie_details()
                return
            }
            if (expectedActionPostLogin == "see_invite_details") {
                show_loading_indicator_on_primary_bottom_bar(true, "Next")
                pull_recent_greetie_details()
                return
            }



        }).catch((error) => {
            $("#login_modal_verify_otc_button").html("Verify SMS Code");
            $("#login_sms_otc_code").addClass("is-invalid")
            alert(error.message)
        })
    }
})





