window.show_onboarding_ai_writing = function () {
    appData.current_user_view = "choose_ai_writing"
    show_ai_writing_view()
}

window.show_ai_writing_view = function () {
    hide_allViews()
    $("#choose_ai_writing").removeClass("d-none")

    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").html("Create a unique poem")
    $("#bottom_action_bar_button_primary").removeClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")

    scroll.animateScroll(0);
}


window.fetch_ai_writing_poem = function () {

    $("#ai_writing_recipient").removeClass("is-invalid");
    $("#ai_writing_relationship").removeClass("is-invalid");
    $("#ai_writing_special_things").removeClass("is-invalid");
    $("#ai_writing_location").removeClass("is-invalid");

    if ($("#ai_writing_recipient").val() == "") {
        $("#ai_writing_recipient").addClass("is-invalid");
    } else if ($("#ai_writing_relationship").val() == "") {
        $("#ai_writing_relationship").addClass("is-invalid");
    } else if ($("#ai_writing_special_things").val() == "" || $("#ai_writing_special_things").val().length < 20) {
        $("#ai_writing_special_things").addClass("is-invalid");
    } else if ($("#ai_writing_location").val() == "") {
        $("#ai_writing_location").addClass("is-invalid");
    } else {

        $("#bottom_action_bar_button_primary").html(
            `<div class="spinner-border spinner-border-sm me-1" role="status">
            <span class="visually-hidden">Loading...</span>
          </div> Give us 30 seconds...`
        );

        var get_aiWriting = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_aiWriting');
        get_aiWriting({
            recipient: appData.recipient_name,
            occasion: occasionTranslator(appData.occasion),
            type_of_poem: $("#type_of_poem").val(),
            relationship: $("#ai_writing_relationship").val(),
            special_things: $("#ai_writing_special_things").val(),
            location: $("#ai_writing_location").val()
        }).then(function (result) {

            return result

        }).then(function (result) {

            window.show_loading_indicator_on_primary_bottom_bar(false, "Create a unique poem")
            $("#review_ai_writing").html(result.data.trim())
            show_onboarding_ai_writing_review()

        }).catch((error) => {
            console.log(error);
            alert(error.message)
            window.show_loading_indicator_on_primary_bottom_bar(false, "Create a unique poem")
        });
    }

}