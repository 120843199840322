// Vendor
import 'bootstrap';
import 'jarallax';

// Theme
import './defaults/aos';
import './defaults/bigpicture';
import './defaults/bootstrap';
import './defaults/choices';
import './defaults/countup';
import './defaults/dropzone';
import './defaults/highlight';
import './defaults/map';
import './defaults/modal';
import './defaults/navbar-dropdown';
import './defaults/navbar';
import './defaults/popover';
import './defaults/pricing';
import './defaults/quill';
import './defaults/smooth-scroll';
import './defaults/tooltip';
import './defaults/typed';
import './defaults/lightgallery';

// User
import './defaults/user.js';

// Tools
import './tools.js';

// Data
import './data/music.js';
import './data/vibes.js';
import './data/empty_state_suggestions.js';

// Controllers
import './controllers/app/onboarding_choose_expression.js';
import './controllers/app/onboarding_choose_vibe.js';
import './controllers/app/onboarding_personalize.js';
import './controllers/app/onboarding_prompt_instructions.js';
import './controllers/app/onboarding_choose_music.js';
import './controllers/app/onboarding_choose_photos.js';
import './controllers/app/onboarding_ready_to_upload.js';
import './controllers/app/onboarding_uploading_shopping.js';
import './controllers/app/show_latest_greetie.js';
import './controllers/app/login_controls.js';
import './controllers/app/bottom_bar_controller.js';
import './controllers/app/onboarding_write_note.js';
import './controllers/app/choose_font.js';
import './controllers/app/invite_controls.js';
import './controllers/app/onboarding_choose_gif.js';
import './controllers/app/onboarding_ai_writing.js';
import './controllers/app/onboarding_ai_writing_review.js';
import './controllers/app/onboarding_choose_location.js';

// Account Controllers
import './controllers/account/list_greetings.js';

// Create page controllers
import './controllers/create_pages/create_page_login.js';