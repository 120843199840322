
window.parseURLParams = function (url) {
    var queryStart = url.indexOf("?") + 1,
        queryEnd = url.indexOf("#") + 1 || url.length + 1,
        query = url.slice(queryStart, queryEnd - 1),
        pairs = query.replace(/\+/g, " ").split("&"),
        parms = {}, i, n, v, nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
        nv = pairs[i].split("=", 2)
        n = decodeURIComponent(nv[0])
        v = decodeURIComponent(nv[1])

        if (!parms.hasOwnProperty(n)) parms[n] = []
        parms[n].push(nv.length === 2 ? v : null)
    }
    return parms;
}


window.hide_allViews = function () {
    $("#watch_greeting_view").addClass("d-none")
    $("#verify_number_view").addClass("d-none")
    $("#personalize_greeting_view").addClass("d-none")
    $("#confirm_upload_view").addClass("d-none")
    $("#uploading_shop_view").addClass("d-none")
    $("#choose_ad_options_view").addClass("d-none")
    $("#choose_expire_view").addClass("d-none")

    $("#choose_expression_view").addClass("d-none")
    $("#choose_photos").addClass("d-none")
    $("#choose_music").addClass("d-none")
    $("#choose_gif").addClass("d-none")
    $("#choose_gif").addClass("d-none")
    $("#choose_ai_writing_review").addClass("d-none")
    $("#choose_ai_writing").addClass("d-none")
    $("#choose_location").addClass("d-none")

    $("#create_greeting_screen").addClass("d-none")
    $("#choose_prompt").addClass("d-none")
    $("#button_action_bar").addClass("d-none")
    $("#choose_vibe").addClass("d-none")
    $("#choose_font").addClass("d-none")
    $("#choose_write_note").addClass("d-none")

    $(".bg-fcm-color-gradient").css('background', "radial-gradient(77.81% 259.29% at 14.51% 11.25%, #E9FFFB 16.2%, #FDF1FF 100%)");
    $("#primary_container").removeClass("watch_greeting_view_background_image")

    $("#choose_font").off("change", ':checkbox')
    $('#switch_invert_font_color').prop('checked', false);
}


window.requestUploadUrlAndBeginFileUpload = function (uploadType) {
    var get_codeUploadUrl = firebaseUtils.httpsCallable(firebaseUtils.functions, 'get_codeUploadUrl');

    var recip_name = $("#recipients_name_personalize_card").val()

    // note, photo, video

    // Need just a list of name of files
    var fileNameList = []
    appData.photoList.forEach(function (imageFile) {
        fileNameList.push(imageFile[1])
    })

    var videoFile = ""
    if (uploadType == "video") {
        videoFile = appData.videoFile
    }

    var userNote = ""
    if (uploadType == "note") {
        userNote = $('#write_a_note_textarea').val()
    }
    if (uploadType == "poem") {
        userNote = $('#review_ai_writing').val()
    }
    if (uploadType == "location") {
        userNote = {
            "text": $('#add_location_input_field').val(),
            "coordinates": tempData.locationCord
        }
    }
    if (uploadType == "gif") {
        userNote = appData.selected_gif_id
    }

    var musicSelection = ""
    if (uploadType == "photo" && appData.selectedMusicIndex != -1) {
        appData.musicSelection.forEach(function (item) {
            if (id == item[2]) {
                musicSelection = item[3]
            }
        })
    }

    get_codeUploadUrl({
        initType: uploadType,
        photo_list: fileNameList,
        docId: appData.extracted_url_id,
        music_selection: musicSelection,
        userNote: userNote,
        mime_Type: videoFile.type,
        recipient_name: appData.recipient_name,
        sender_name: appData.onboarding_temp_giver_name,
        user_selected_occasion: appData.occasion,
        video_chosen_prompt_string: appData.onboarding_video_occasion_type
    }).then(function (result) {

        // Reset everything back to defaults so we don't need to refresh
        appData.selectedMusicIndex = -1
        appData.vibeSelection = -1
        $("#recipients_name_personalize_card").val("")
        $("#occasion_choose_prompt_dropdown").val("birthday")
        $(".textarea-themed-input").val("")

        var data = result.data
        var signed_upload_urls = data.signed_upload_urls

        var index = 0;
        var completedPhotoTasks = 0

        signed_upload_urls.forEach(signedUrl => {
            var videoFile;
            if (uploadType == "photo") {
                videoFile = appData.photoList[index][2]
            } else {
                videoFile = appData.videoFile
            }

            const formData = new FormData();
            const uploadFile = new File([videoFile], data.required_file_names[index], {
                type: data.required_file_names[index].split('.').pop(),
            });

            for (const field in signedUrl.fields) {
                formData.append(field, signedUrl.fields[field]);
            }

            // Add the file to the form data
            formData.append('file', uploadFile);

            let request = new XMLHttpRequest()
            request.open('POST', signedUrl.url)

            request.upload.addEventListener('progress', function (e) {
                if (uploadType == "video") {
                    let percent_completed = (((e.loaded / e.total) * 100) / 2).toFixed(0)
                    $(".saving_content_title").text("Uploading files...")
                    $(".saving_content_progress_loader").css('width', percent_completed+'%');
                }
            });


            request.addEventListener('load', function (e) {
                if (uploadType == "photo") {
                    completedPhotoTasks = completedPhotoTasks + 1
                    let percent_completed = (((completedPhotoTasks / signed_upload_urls.length) * 100) / 2).toFixed(0)

                    $(".saving_content_title").text("Uploading files...")
                    $(".saving_content_progress_loader").css('width', percent_completed+'%');

                    if (completedPhotoTasks == signed_upload_urls.length) {
                        // We are done saving this content
                        appData.preventRedirectUploadingContent = false
                        beginProcessingCountdown(uploadType)
                        appData.photoList = []
                    }
                }
                if (uploadType == "video") {
                    confirmUploadAndTranscode(data.required_file_names[0], data.required_file_names[0].split('.').pop())
                }
            });
            index = index + 1
            request.send(formData);
        })

        if (uploadType == "note" || uploadType == "poem" || uploadType == "gif" || uploadType == "location") {
            // We are done saving this content
            appData.preventRedirectUploadingContent = false
            beginProcessingCountdown(uploadType)
        }

    })
}

window.beginProcessingCountdown = function (uploadType) {
    var timeleft = 0;
    var countDownTime = 85
    if (uploadType == "note" || uploadType == "poem" || uploadType == "gif" || uploadType == "location") {
        countDownTime = 15
    }
    if (uploadType == "photo") {
        countDownTime = 60
    }

    var downloadTimer = setInterval(function () {
        if (timeleft >= countDownTime) {
            clearInterval(downloadTimer);

            resetBottomBarButtons()
            $("#bottom_action_bar_button_primary").html("Preview")
            $("#bottom_action_bar_button_secondary").html("Add more")

            $(".saving_content_title").text("👍  Your changes are saved")
            $(".saving_content_progress_loader").addClass("bg-success")
            $(".saving_content_subtext").text("You can leave this page now.")
            $(".saving_content_progress_loader").css('width', '100%');
            
            showButtonBar(true, false)

        } else {
            if (uploadType == "note" || uploadType == "poem" || uploadType == "gif" || uploadType == "location") {

                $(".saving_content_title").text("Saving your changes...")
                $(".saving_content_progress_loader").css('width', (Math.round((timeleft / countDownTime) * 100))+'%');

            } else {

                $(".saving_content_title").text("Processing your files...")
                $(".saving_content_progress_loader").css('width', (Math.round(((timeleft / countDownTime) * 100) / 2) + 50)+'%');

            }
            timeleft += 1;
        }
    }, 200);
}

window.confirmUploadAndTranscode = function (generatedFileName, fileExtension) {
    var post_confirmVideoUploaded = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_confirmVideoUploaded');
    post_confirmVideoUploaded({
        generatedFileName: generatedFileName,
        fileExtension: fileExtension,
        tagId: appData.extracted_url_id
    }).then(function () {
        beginProcessingCountdown()
        // We are done saving this content. now it's processing
        appData.preventRedirectUploadingContent = false
    }).catch((error) => {
        console.error('Error:', error);
    });
}

window.claimCardRecipient = function () {
    if (appData.has_recipient_claimed == false) {
        var post_claimCardRecipient = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_claimCardRecipient');
        post_claimCardRecipient({
            card_id: appData.extracted_url_id
        }).then(function () {
            console.log("user claimed this tag");
            appData.has_recipient_claimed = true
            $("#confirm_recipient_of_greeting").html("I'm the recipient")
            $("#claim_greeting_modal").modal("hide");
        }).catch((error) => {
            console.error('Error:', error);
            $("#confirm_recipient_of_greeting").html("I'm the recipient")
            $("#claim_greeting_modal").modal("hide");
        });
    }
}

window.toggle_save_greeting_account = function (isRemove) {
    var post_toggleFavoriteGreeting = firebaseUtils.httpsCallable(firebaseUtils.functions, 'post_toggleFavoriteGreeting');
    post_toggleFavoriteGreeting({
        remove_greeting: isRemove,
        greeting_id: appData.extracted_url_id
    }).then(function () {

        $(".count-down-timer-expression-container").addClass("d-none")
        $(".countdown_to_expire_timer").addClass("d-none")

        $(".save_greeting_expression_feed_image").removeClass("d-none")

        if (isRemove) {
            $(".save_greeting_to_account").removeClass("d-none")
            $(".remove_greeting_to_account").addClass("d-none")
            $(".save_greeting_to_account_title").html("Make sure "+appData.sender_name+"'s greeting stays with you. Save it now and see it in your account.")
        } else {
            $(".save_greeting_to_account").addClass("d-none")
            $(".remove_greeting_to_account").removeClass("d-none")
            $(".save_greeting_to_account_title").html(appData.sender_name+"'s greeting is securely saved in your account and it will never expire.")
        }
        $(".save_greeting_to_account").html("Save");
        $(".remove_greeting_to_account").html("Remove from saved");
    }).catch((error) => {
        console.error('Error:', error);
    });
}






var add_expression_menu_options_invite = [
    {
        "title": "Write an AI description",
        "subtitle": "Make it funny, serious or weird",
        "icon": "/assets/img/ai_poem_expression.png",
        "button_id": "button_click_edit_add_note"
    },
    {
        "title": "Add a location",
        "subtitle": "Add a place or address",
        "icon": "/assets/img/location_expression.png",
        "button_id": "button_click_edit_add_location"
    },
    {
        "title": "Add a photo slideshow",
        "subtitle": "with music from Soundcloud artists",
        "icon": "/assets/img/photo_upload_option.png",
        "button_id": "button_click_edit_add_photo"
    },
    {
        "title": "Add a fun GIF",
        "subtitle": "powered by GIPHY",
        "icon": "/assets/img/meme.png",
        "button_id": "button_click_edit_add_gif"
    },
    {
        "title": "Record or upload a video",
        "subtitle": "using thoughtful prompts",
        "icon": "/assets/img/video_upload_option.png",
        "button_id": "button_click_edit_add_video"
    },
]


var add_expression_menu_options_greeting = [
    {
        "title": "Add a photo slideshow",
        "subtitle": "with music from Soundcloud artists",
        "icon": "/assets/img/photo_upload_option.png",
        "button_id": "button_click_edit_add_photo"
    },
    {
        "title": "Record or upload a video",
        "subtitle": "using thoughtful prompts",
        "icon": "/assets/img/video_upload_option.png",
        "button_id": "button_click_edit_add_video"
    },
    {
        "title": "Generate an AI poem",
        "subtitle": "using the power of ChatGPT",
        "icon": "/assets/img/ai_poem.png",
        "button_id": "button_click_edit_add_ai_poem"
    },
    {
        "title": "Add a fun GIF",
        "subtitle": "powered by GIPHY",
        "icon": "/assets/img/meme.png",
        "button_id": "button_click_edit_add_gif"
    },
    {
        "title": "Add a gift they'll love",
        "subtitle": "great gifts shipped directly to your recipient",
        "icon": "/assets/img/gift_option.png",
        "button_id": "button_click_edit_add_gift_card"
    },
    {
        "title": "Write a note",
        "subtitle": "with a boring text box",
        "icon": "/assets/img/note.png",
        "button_id": "button_click_edit_add_note"
    },
    {
        "title": "Add a location",
        "subtitle": "Add a place or address",
        "icon": "/assets/img/location_expression.png",
        "button_id": "button_click_edit_add_location"
    }
]



/**
 * @function show_edit_modal
 * @description Opens a modal to allow the user to edit an item, such as a photo, location, or video. The modal's appearance depends on the type of the invite and whether the user is using iOS or a different operating system.
 */
window.show_edit_modal = function() {
    // Access the container where we'll add our expressions
    const modalContainer = $('.modal_add_expressions_container');
    modalContainer.html(""); // clear the container first

    // Array that we'll be looping over, depending on the invite type
    const expressionsArray = appData.is_type_invite ? add_expression_menu_options_invite : add_expression_menu_options_greeting;

    // Function to generate the HTML for the expression menu
    generateExpressionMenu(expressionsArray, modalContainer);

    // Event listeners for the different buttons in the modal
    $("#button_click_edit_add_photo").on("click", onAddPhotoClick);
    $("#button_click_edit_add_location").on("click", onAddLocationClick);
    $("#button_click_edit_add_video").on("click", onAddVideoClick);
    $("#button_click_edit_add_note").on("click", onAddNoteClick);
    $("#button_click_edit_add_gift_card").on("click", onAddGiftCardClick);
    $("#button_click_edit_add_gif").on("click", onAddGifClick);
    $("#button_click_edit_add_ai_poem").on("click", onAddAiPoemClick);

    // Show the modal
    $("#show_edit_greetie_options").modal("show");
};

/**
 * @function generateExpressionMenu
 * @description Generates the HTML for the expression menu and appends it to the provided container.
 * @param {Array} expressionsArray - The array of expression menu options to iterate over.
 * @param {jQuery} container - The container to which the generated HTML will be appended.
 */
function generateExpressionMenu(expressionsArray, container) {
    expressionsArray.forEach(function(item) {
        const template = $($('#modal_add_expression_type_object').html());
        $(template).attr('id', item.button_id)
        $(template).find("h4").html(item.title)
        $(template).find("p").html(item.subtitle)
        $(template).find("img").attr('src', item.icon)
        container.append(template);
    });
}

// The following are the click event handlers for the different buttons
window.onAddPhotoClick = function() {
    hide_edit_modal();
    appData.current_user_view = "choose_photos_edit";
    draw_choose_photos_views();
}

window.onAddLocationClick =function() {
    hide_edit_modal();
    show_onboarding_choose_location();
}

window.onAddVideoClick = function() {
    hide_edit_modal();
    const browser = bowser.getParser(window.navigator.userAgent);

    if ((browser.getOSName(true) === "ios" && browser.getBrowserName(true) !== "safari") ||
        (browser.getOSName(true) === "ios" && browser.getBrowserVersion(true) === undefined && isAppClip === false)) {
        console.log("This browser does not support recording...");
        $('#incompatibleBrowserOniOSModal').modal("show");
    } else {
        appData.current_user_view = "select_prompt_edit";
        draw_prompt_instructions();
    }
}

window.onBackFromChromeVideoClick = function() {
    $('#incompatibleBrowserOniOSModal').modal("hide");
    show_edit_modal();
}

window.onAddNoteClick = function() {
    hide_edit_modal();
    appData.current_user_view = "write_note_edit";
    show_write_note();
}

window.onAddGiftCardClick = function() {
    hide_edit_modal();
    appData.current_user_view = "select_gift_edit";
    $(".uploading_view_elem_within_uploading").addClass("d-none");
    draw_shopping();

    showButtonBar(true, false);
    $("#bottom_action_bar_button_primary").addClass("d-none");
    $("#bottom_action_bar_button_secondary").html("Back");
}

window.onAddGifClick = function() {
    hide_edit_modal();
    appData.current_user_view = "choose_gif_edit";
    show_choose_gif();
}

window.onAddAiPoemClick = function() {
    hide_edit_modal();
    appData.current_user_view = "choose_ai_writing";
    show_ai_writing_view();
}


/**
 * @function hideEditModal
 * @description Closes the modal for editing and cleans up event listeners.
 */
window.hide_edit_modal = function() {
    // Hide the modal
    $("#show_edit_greetie_options").modal("hide");

    // Remove event listeners for performance reasons and to avoid potential memory leaks
    $("#button_click_edit_add_photo").off("click");
    $("#button_click_edit_add_location").off("click");
    $("#button_click_edit_add_video").off("click");
    $("#button_click_edit_add_note").off("click");
    $("#button_click_edit_add_gift_card").off("click");
    $("#button_click_edit_add_gif").off("click");
    $("#button_click_edit_add_ai_poem").off("click");
};


window.show_delete_content_modal = function () {
    $("#remove_video_modal").modal("show");
}

window.hide_delete_video_modal = function () {
    $("#remove_video_modal").modal("hide");
}

window.show_delete_code_modal = function () {
    $("#delete_code_modal").modal("show");
}

window.hide_delete_code_modal = function () {
    $("#delete_code_modal").modal("hide");
}



