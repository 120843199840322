
import { throttle } from 'throttle-debounce'
import { renderGrid } from '@giphy/js-components'
import { GiphyFetch } from '@giphy/js-fetch-api'

window.show_onboarding_choose_gif = function () {
    appData.current_user_view = "choose_gif"
    show_choose_gif()
}

window.show_choose_gif = function () {

    hide_allViews()
    $("#choose_gif").removeClass("d-none")

    showButtonBar(true, false)
    $("#bottom_action_bar_button_primary").addClass("d-none")
    $("#bottom_action_bar_button_secondary").html("Back")

    scroll.animateScroll(0);
    $("#gif_search_term").attr("placeholder",occasionTranslator(appData.occasion))

    appData.gf = new GiphyFetch('5SSExMH6nNjOifOO5Wbleiebt80pLQ5z')
    const fetchGifs = (offset) => {
        var term = $("#gif_search_term").val()
        if(term != ""){
            return appData.gf.search(term, { offset, limit: 25 })
        }
        return appData.gf.search(occasionTranslator(appData.occasion)+" Trending", { offset, limit: 25 })
    }

    // Creating a grid with window resizing and remove-ability
    const makeGrid = (targetEl) => {

        $("#bottom_action_bar_button_primary").addClass("d-none")
        $("#bottom_action_bar_button_primary").html("Save")

        const render = () => {
            // here is the @giphy/js-components import
            return renderGrid(
                {
                    width: $(".gif_grid").width(),
                    fetchGifs,
                    columns: 2,
                    gutter: 8,
                    hideAttribution: true,
                    noLink: true,
                    onGifClick: (gif, e) => select_gif(gif, e),
                },
                targetEl
            )
        }
        const resizeRender = throttle(500, render)
        window.addEventListener('resize', resizeRender, false)
        const remove = render()
        return {
            remove: () => {
                remove()
                window.removeEventListener('resize', resizeRender, false)
            },
        }
    }

    // Instantiate
    var grid = makeGrid(document.querySelector('.gif_grid'))

    function select_gif(gif, e) {
        $(".giphy-gif-img").each(function () {
            $(this).removeClass("color-border")
        });
        $(e.target).addClass("color-border")
        appData.selected_gif_id = gif.id
        $("#bottom_action_bar_button_primary").removeClass("d-none")
        $("#bottom_action_bar_button_primary").html("Save")
    }

    $('#search_gif_bar').on('submit', function (event) {
        event.preventDefault();
        grid.remove()
        grid = makeGrid(document.querySelector('.gif_grid'))
    })
}